import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Inject, Injectable } from '../../di';
import { HttpClient } from '../../http';
import { VeggaLoader } from '../../http/veggaLoader';
import { VeggaResponse } from '../../http/veggaResponse';
import { VeggaFacade } from '../vegga-facade/vegga-facade';
import { EMPTY_RESPONSE, PlacesRequest, PlacesResponse } from './places.entities';

@Injectable('placesFacade')
export class PlacesFacade {
  @Inject('veggaFacade') private veggaFacade: VeggaFacade;
  @Inject('httpClient') private httpClient: HttpClient;

  private placesResponse = new VeggaResponse<PlacesResponse>();
  private loader = new VeggaLoader();

  get places$(): Observable<PlacesResponse> {
    return this.placesResponse.value$;
  }

  /**
   * Calls Nominatim API to get a place data according passed location data.
   * @param location object containing location data
   * @param onlyAddress only address property will be used for the request in case of true
   * @returns Observable containing place info Nominatim response.
   */
  loadPlacesByAddress(placesRequest: PlacesRequest, onlyAddress = false) {
    const query = Object.keys(placesRequest).reduce((query, key) => {
      return `${key}=${placesRequest[key as keyof PlacesRequest]}`;
    }, '');
    const req$ = this.httpClient
      .get<PlacesResponse>(`${environment.PLACES_ENDPOINT}/search.php?${onlyAddress ? `q=${placesRequest.street}` : query}&polygon_geojson=1&format=jsonv2`, {})
      .pipe(
        // if void array, throw an error
        map((places: PlacesResponse) => {
          if (places.length) {
            return places;
          } else {
            throw EMPTY_RESPONSE;
          }
        }),
      );

    const subscription = req$.subscribe({
      next: res => {
        this.placesResponse.set(res);
      },
      error: err => {
        this.placesResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  clear(): void {}
}
