//@ts-ignore: In-browser module import
import { HttpEvent } from '@vegga-api-clients/irrigation-control-service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpMethod } from '../http';
import { HttpRequest } from '../http/request';

export interface VeggaToastConfig {
  message: string;
  statusClass: string;
}

const EXCLUDED_PATHS = [
  'io/status',
  '/communications/agrobeeL1/'
];

const EXCLUDED_QUERIES = [
  'getResult'
];

const EXCLUDED_PATH_ERRORS = [
  '/exports/irrigation/'
]

export class ServerMessageInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return (next.handle(req) as Observable<HttpEvent<any>>).pipe(
      tap(() => {
        // WORKAROUND: ASK BACKEND FOR URL CHANGE
        const hasExcludedPropInBody = req.body && req.body.dryRun;
        const isExcluded = EXCLUDED_PATHS.some(excludedPath => req.url.includes(excludedPath)) || Object.keys(req.query || {}).some(queryKey => EXCLUDED_QUERIES.includes(queryKey)) || hasExcludedPropInBody;

        if (isExcluded) {
          return;
        }

        switch (req.method) {
          case HttpMethod.POST:
          case HttpMethod.PUT:
            this.showVeggaToast({ message: 'Guardado con éxito', statusClass: 'success' });
            break;
          case HttpMethod.DELETE:
            this.showVeggaToast({ message: 'Eliminado con éxito', statusClass: 'success' });
            break;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error && error.status !== 0 && !EXCLUDED_PATH_ERRORS.some(path => req.url.includes(path))) {
          const message = (error.error && error.error.errorCode) || 'Se ha producido un error'
          this.showVeggaToast({ message, statusClass: 'error' });
        }
        throw error
      }))

  }

  /**
   * Shows a toast with passed message and styles
   */
  private showVeggaToast({ message, statusClass }: VeggaToastConfig): void {
    const toast = document.querySelector('vegga-toast-manager');
    (toast as any).create({ message, statusClass })
  }
}