export const MOCK_WEATHER_CONTROL_POINT_DATA = {
    "id": 3,
    "name": "AgroSens Meteo",
    "model": null,
    "packCode": null,
    "type": "WEATHER",
    "sensorPack": null,
    "sensors": [
        {
            "id": 19,
            "deviceId": 10045,
            "sensorCategory": "WIND_DIRECTION",
            "sensorType": "ANALOG",
            "sensorDepth": null,
            "readings": {
                "currentValue": {
                    "value": 0,
                    "valueFormatted": "0",
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º"
                }
            },
            "progresId": 21,
            "isCalculated": null
        },
        {
            "id": 23,
            "deviceId": 10045,
            "sensorCategory": "ATMOSPHERIC_PRESSURE",
            "sensorType": "ANALOG",
            "sensorDepth": null,
            "readings": {
                "currentValue": {
                    "value": 991,
                    "valueFormatted": "991",
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa"
                }
            },
            "progresId": 25,
            "isCalculated": null
        },
        {
            "id": 26,
            "deviceId": 10045,
            "sensorCategory": "RAIN_GAUGE",
            "sensorType": "METER",
            "sensorDepth": null,
            "readings": {
                "currentValue": {
                    "value": 0,
                    "valueFormatted": "0.0",
                    "unit": "CUBIC_METERS_PER_HOUR",
                    "symbol": null
                }
            },
            "progresId": 3,
            "isCalculated": null
        },
        {
            "id": 20,
            "deviceId": 10045,
            "sensorCategory": "ANEMOMETER",
            "sensorType": "ANALOG",
            "sensorDepth": null,
            "readings": {
                "currentValue": {
                    "value": 0,
                    "valueFormatted": "0",
                    "unit": "KILOMETERS_PER_HOUR",
                    "symbol": "km/h"
                }
            },
            "progresId": 22,
            "isCalculated": null
        },
        {
            "id": 21,
            "deviceId": 10045,
            "sensorCategory": "TEMPERATURE",
            "sensorType": "ANALOG",
            "sensorDepth": null,
            "readings": {
                "currentValue": {
                    "value": 11.9,
                    "valueFormatted": "11.9",
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC"
                }
            },
            "progresId": 23,
            "isCalculated": null
        },
        {
            "id": 24,
            "deviceId": 10045,
            "sensorCategory": "RADIATION",
            "sensorType": "ANALOG",
            "sensorDepth": null,
            "readings": {
                "currentValue": {
                    "value": 203,
                    "valueFormatted": "203",
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2"
                }
            },
            "progresId": 26,
            "isCalculated": null
        },
        {
            "id": 22,
            "deviceId": 10045,
            "sensorCategory": "RELATIVE_HUMIDITY",
            "sensorType": "ANALOG",
            "sensorDepth": null,
            "readings": {
                "currentValue": {
                    "value": 67,
                    "valueFormatted": "67",
                    "unit": "PERCENT",
                    "symbol": "%"
                }
            },
            "progresId": 24,
            "isCalculated": null
        },
        {
            "id": 25,
            "deviceId": 10045,
            "sensorCategory": "WET_BULB",
            "sensorType": "ANALOG",
            "sensorDepth": null,
            "readings": {
                "currentValue": {
                    "value": 8.7,
                    "valueFormatted": "8.7",
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC"
                }
            },
            "progresId": 27,
            "isCalculated": null
        }
    ],
    "farmId": 1,
    "farmName": "La Serra",
    "geoJson": "{ \"type\": \"Feature\", \"properties\": {}, \"geometry\": { \"coordinates\": [ 0.8706012556538951, 41.61802393073731 ], \"type\": \"Point\" } }",
    "availableAgronomicVariables": [
        "ANTI_FROST_SURVEILLANCE",
        "WET_BULB",
        "DEW_POINT",
        "VAPOR_PRESSURE_DEFICIT",
        "EFFECTIVE_RAIN",
        "ETO"
    ]
}

export const MOCK_WEATHER_DATA = [
    {
        "id": 21,
        "progresId": 23,
        "grouping": "HOUR",
        "sensorCategory": "TEMPERATURE",
        "sensorType": "ANALOG",
        "series": {
            "MINIMUM": [
                {
                    "x": 1737932400000,
                    "y": 7.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 7.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 8.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 9.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 9.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 9.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 10.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 10.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 10.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 10.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 13.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 14.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 16.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 15.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 15.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 14.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 13.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 12.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 12,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 12.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 12.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 11.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 10.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 10.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 9.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 9.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 8.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 8.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 4.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 9.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 10.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 11.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 11.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 11.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 12.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 12.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 11.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 10.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 10.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 10.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 9.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 9.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 8.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 7.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 3.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 8.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 9.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 10.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 10.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 11.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 10.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 9.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 8.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 6.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 4.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 3.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 7.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 8.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 9.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 9.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 9.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 10.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 7.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 5.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 8.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 9.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 12.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 12.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 9.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 5.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 4.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": -0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 3.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 4.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 5.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 6.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 8.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 8.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 4.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": -0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 3.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 8.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 2.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": -0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": -1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": -1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": -2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": -2.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": -2.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": -1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 2.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 8.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 4.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 2.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": -0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": -1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": -1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": -2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": -2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": -2.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": -2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": -2.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": -2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": -2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": -2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": -2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 2.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 8.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 10.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 8.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 3.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": -0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": -1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": -2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": -2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": -2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 5.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 9.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 10.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 9.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 6.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": -1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": -1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": -2.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": -2.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": -2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": -1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 5.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 8.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 9.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 10.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 9.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": -1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": -1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": -2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": -2.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": -1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 10.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                }
            ],
            "MAXIMUM": [
                {
                    "x": 1737932400000,
                    "y": 8.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 8.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 9.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 9.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 9.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 9.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 10.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 10.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 13.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 14.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 17.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 17.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 16.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 15.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 15.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 15.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 13.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 12.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 14,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 14.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 12.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 11.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 10.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 10.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 9.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 8.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 8.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 6.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 9.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 11.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 12.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 13.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 13.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 14.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 14.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 13.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 11.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 10.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 10.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 9.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 9.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 8.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 7.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 5.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 9.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 9.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 10.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 11.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 12.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 12.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 10.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 9.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 9.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 8.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 8.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 8.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 4.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 4.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 7.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 7.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 9.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 9.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 9.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 9.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 10.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 11.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 5.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 4.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 7.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 8.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 9.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 11.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 12.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 13.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 13.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 13.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 9.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 5.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 4.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 5.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 8.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 9.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 8.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 4.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 2.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 3.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 8.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 9.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 9.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": -0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": -0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": -1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": -0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": -1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 8.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 9.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 9.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 9.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 4.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 2.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": -0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": -0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": -1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": -1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 8.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 10.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 12.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 12.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 8.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 4.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 3.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 8.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 9.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 12.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 13,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 9.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": -1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": -0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 8.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 9.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 11.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 11.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 12.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 9.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": -0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": -0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 10.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 10.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                }
            ],
            "AVERAGE": [
                {
                    "x": 1737932400000,
                    "y": 8.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 7.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 9.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 9.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 9.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 9.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 10.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 10.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 10.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 11.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 14.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 15.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 16.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 15.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 15.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 14.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 14.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 12.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 12.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 13.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 13,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 11.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 10.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 10.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 9.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 9.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 9.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 8.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 7.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 5.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 8.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 10.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 12.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 12.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 13,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 13.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 12.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 11.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 10.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 10.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 10.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 9.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 9.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 8.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 8.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 7.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 6.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 3.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 4.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 7.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 10.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 10.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 10.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 11.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 11.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 10,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 9.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 8.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 8.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 8.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 5.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 3.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 4.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 7.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 8.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 9.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 9.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 9.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 9.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 10.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 10.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 8.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 5.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 4.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 3.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 3.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 9.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 10.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 11.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 12.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 13.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 12.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 9.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 7.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 5.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 6.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 8.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 8.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 8.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 8.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 8.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": -0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": -1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": -0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 9.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 8.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 3.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": -1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": -2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": -2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": -2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": -2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 9.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 11.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 11.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": -0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": -0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": -1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": -1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 2.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 4.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 8.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 10.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 11.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 11.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": -1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": -1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": -2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 10.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 11.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 11.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 7.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 4.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 2.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": -0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": -0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 9.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 10.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                }
            ]
        }
    },
    {
        "id": 23,
        "progresId": 25,
        "grouping": "HOUR",
        "sensorCategory": "ATMOSPHERIC_PRESSURE",
        "sensorType": "ANALOG",
        "series": {
            "MINIMUM": [
                {
                    "x": 1737932400000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 978,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 977,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 977,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 977,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 976,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 976,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 975,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 975,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 975,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 978,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 978,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 987,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 987,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 988,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 989,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 989,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 988,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 987,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 987,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 988,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 989,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                }
            ],
            "MAXIMUM": [
                {
                    "x": 1737932400000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 978,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 978,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 977,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 977,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 977,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 976,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 975,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 978,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 978,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 987,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 988,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 988,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 989,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 989,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 988,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 987,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 987,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 988,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 989,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 1005,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 1005,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                }
            ],
            "AVERAGE": [
                {
                    "x": 1737932400000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 978,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 977,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 977,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 977,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 976,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 976,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 975,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 975,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 977,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 978,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 978,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 987,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 987,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 988,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 989,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 989,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 988,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 987,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 987,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 988,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 989,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                }
            ]
        }
    },
    {
        "id": 20,
        "progresId": 22,
        "grouping": "HOUR",
        "sensorCategory": "ANEMOMETER",
        "sensorType": "ANALOG",
        "series": {
            "MINIMUM": [
                {
                    "x": 1737932400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                }
            ],
            "MAXIMUM": [
                {
                    "x": 1737932400000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 15,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 33,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 33,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 33,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 32,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 37,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 36,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 27,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 15,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 12,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 11,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 42,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 41,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 36,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 46,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 25,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 35,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 24,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 20,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 22,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 16,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 9,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 15,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 16,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 9,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 9,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 12,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 16,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 16,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 11,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 18,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 20,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 20,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 25,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 27,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 22,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 12,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 17,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 17,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 15,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 21,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 17,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 17,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 17,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 9,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 9,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 9,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 9,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 14,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 15,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 12,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 11,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 11,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 9,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 11,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 9,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 9,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 11,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 12,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 18,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 14,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 17,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                }
            ],
            "AVERAGE": [
                {
                    "x": 1737932400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 18,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 11,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 14,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 12,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 9,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                }
            ]
        }
    },
    {
        "id": 19,
        "progresId": 21,
        "grouping": "HOUR",
        "sensorCategory": "WIND_DIRECTION",
        "sensorType": "ANALOG",
        "series": {
            "MINIMUM": [
                {
                    "x": 1737932400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 189,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 195,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 184,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 56,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 82,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 166,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 160,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 171,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 148,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 166,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 162,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 168,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 164,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 218,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 154,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 205,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 151,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 174,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 24,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 68,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 73,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 244,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 173,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 238,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 229,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 74,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                }
            ],
            "MAXIMUM": [
                {
                    "x": 1737932400000,
                    "y": 97,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 342,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 212,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 212,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 229,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 211,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 225,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 259,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 273,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 235,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 273,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 266,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 202,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 200,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 255,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 246,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 226,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 228,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 265,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 221,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 227,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 222,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 235,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 239,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 286,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 240,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 297,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 213,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 196,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 86,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 84,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 90,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 307,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 310,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 193,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 211,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 244,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 211,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 182,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 211,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 227,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 194,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 202,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 229,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 250,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 217,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 228,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 247,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 256,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 221,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 175,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 187,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 189,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 186,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 199,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 196,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 196,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 211,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 193,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 194,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 100,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 130,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 154,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 348,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 163,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 95,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 275,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 256,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 267,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 196,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 198,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 195,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 193,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 191,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 194,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 191,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 351,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 164,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 131,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 97,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 155,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 155,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 164,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 171,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 103,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 344,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 310,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 301,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 271,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 262,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 262,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 230,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 237,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 202,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 185,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 343,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 343,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 167,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 167,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 180,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 149,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 198,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 105,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 173,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 100,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 281,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 153,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 186,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 177,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 310,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 235,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 311,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 289,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 300,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 327,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 326,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 349,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 352,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 151,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 151,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 120,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 167,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 126,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 111,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 83,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 121,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 111,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 111,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 96,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 110,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 144,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 92,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 294,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 95,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 336,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 336,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 338,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 304,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 349,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 347,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 347,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 94,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 94,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 102,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 133,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 114,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 115,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 109,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 140,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 128,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 95,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 28,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 97,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 96,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 102,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 316,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 291,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 316,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 275,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 275,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 293,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 292,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 112,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 151,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 103,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 103,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 112,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 114,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 109,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 117,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 124,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 106,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 112,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 108,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 119,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 126,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 279,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 319,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 311,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 286,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 265,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 259,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 153,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 106,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 99,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 106,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 102,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 135,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 112,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 110,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 112,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 99,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 121,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 106,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 115,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 111,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 104,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 274,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 322,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 322,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 281,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 256,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 184,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 132,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 112,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 114,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 99,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 91,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 119,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 103,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 99,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 117,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 128,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 160,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 166,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 128,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 101,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 90,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 355,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 32,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                }
            ],
            "AVERAGE": [
                {
                    "x": 1737932400000,
                    "y": 8,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 64,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 17,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 17,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 36,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 49,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 126,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 82,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 117,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 215,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 215,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 213,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 169,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 82,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 37,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 27,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 50,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 39,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 119,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 148,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 188,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 207,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 212,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 219,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 127,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 46,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 180,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 172,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 172,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 21,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 40,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 46,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 20,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 102,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 76,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 83,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 127,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 150,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 134,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 160,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 176,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 112,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 171,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 184,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 199,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 119,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 80,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 37,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 60,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 37,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 74,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 60,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 78,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 141,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 182,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 166,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 181,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 190,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 175,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 82,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 6,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 58,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 31,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 96,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 49,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 55,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 120,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 178,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 90,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 6,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 162,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 181,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 177,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 176,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 177,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 132,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 111,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 47,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 68,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 37,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 25,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 48,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 54,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 72,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 46,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 43,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 209,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 249,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 221,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 227,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 129,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 109,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 169,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 131,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 175,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 188,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 68,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 16,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 7,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 29,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 53,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 96,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 62,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 41,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 31,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 18,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 36,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 78,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 54,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 120,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 104,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 131,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 126,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 134,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 173,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 151,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 56,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 41,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 42,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 72,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 56,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 58,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 47,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 50,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 17,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 27,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 4,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 55,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 7,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 25,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 28,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 22,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 29,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 15,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 132,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 235,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 226,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 207,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 23,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 96,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 44,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 33,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 19,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 79,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 49,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 50,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 44,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 15,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 46,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 80,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 45,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 2,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 10,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 50,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 51,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 129,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 44,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 70,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 98,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 224,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 204,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 25,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 19,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 75,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 91,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 68,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 88,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 74,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 33,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 58,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 75,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 57,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 81,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 89,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 52,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 84,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 32,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 178,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 271,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 228,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 214,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 159,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 21,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 32,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 57,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 83,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 77,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 71,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 59,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 68,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 70,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 57,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 44,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 77,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 89,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 85,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 62,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 43,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 34,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 272,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 245,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 154,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 29,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 77,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 76,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 77,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 75,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 30,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 32,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 23,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 87,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 75,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 65,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 46,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 28,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 83,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 77,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 68,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 111,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 3,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                }
            ]
        }
    },
    {
        "id": 22,
        "progresId": 24,
        "grouping": "HOUR",
        "sensorCategory": "RELATIVE_HUMIDITY",
        "sensorType": "ANALOG",
        "series": {
            "MINIMUM": [
                {
                    "x": 1737932400000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 75,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 75,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 61,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 61,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 64,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 65,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 64,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 58,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 55,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 49,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 44,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 44,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 41,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 41,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 48,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 52,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 55,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 57,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 60,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 64,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 71,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 63,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 63,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 60,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 59,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 62,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 57,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 51,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 51,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 58,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 60,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 65,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 71,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 68,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 71,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 63,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 59,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 54,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 51,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 50,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 50,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 58,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 61,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 68,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 63,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 60,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 62,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 75,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 71,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 68,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 68,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 68,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 75,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 64,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 63,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 62,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 59,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 63,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 60,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 59,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                }
            ],
            "MAXIMUM": [
                {
                    "x": 1737932400000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 75,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 65,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 65,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 61,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 56,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 50,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 48,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 44,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 49,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 52,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 56,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 57,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 61,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 64,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 65,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 63,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 62,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 57,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 58,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 62,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 71,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 65,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 59,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 55,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 52,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 58,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 61,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 68,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 68,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 65,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 75,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 71,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 71,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 75,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 71,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 64,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                }
            ],
            "AVERAGE": [
                {
                    "x": 1737932400000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 62,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 63,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 64,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 68,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 75,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 65,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 61,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 58,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 51,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 46,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 45,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 41,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 46,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 50,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 53,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 55,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 58,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 62,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 64,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 75,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 62,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 60,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 63,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 58,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 54,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 55,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 59,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 63,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 71,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 71,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 61,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 56,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 52,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 50,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 53,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 59,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 63,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 75,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 60,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 62,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 68,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 65,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 65,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 75,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 71,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 64,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 62,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 75,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 65,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 61,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 60,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                }
            ]
        }
    },
    {
        "id": 25,
        "progresId": 27,
        "grouping": "HOUR",
        "sensorCategory": "WET_BULB",
        "sensorType": "ANALOG",
        "series": {
            "MINIMUM": [
                {
                    "x": 1737932400000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 7.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 8.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 8.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 8.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 9.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 11.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 12.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 13.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 13,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 13,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 12.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 12.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 11.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 11.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 11.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 9.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 9.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 8.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 6.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 5.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 4.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 3.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 4.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 7.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 6.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 5.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 5.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 6.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 5.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 5.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 4.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 3.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 2.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 3.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 4.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 7.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 5.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 4.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 3.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 3.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": -0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 5.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 7.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 8.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": -0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": -0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": -0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 4.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": -0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 2.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 2.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 3.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": -2.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": -2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": -2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": -3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": -2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": -2.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": -2.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": -0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 2.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": -0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": -1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": -2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": -2.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": -2.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": -3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": -3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": -2.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": -2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": -3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": -2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": -2.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": -0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 7.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 3.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": -0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": -1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": -1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": -2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": -2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": -2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": -0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 2.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 5.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 7.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 4.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": -0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": -2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": -2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": -2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": -2.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": -2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": -2.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": -3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": -3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": -2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 4.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 5.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 7.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": -0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": -1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": -1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": -2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": -2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": -2.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": -3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": -2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                }
            ],
            "MAXIMUM": [
                {
                    "x": 1737932400000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 7.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 8.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 8.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 8.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 9.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 11.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 13,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 14.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 14.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 13.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 13.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 13.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 13.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 12.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 11.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 11.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 12.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 9.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 9.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 8.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 5.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 4.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 4.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 4.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 7.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 7.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 7.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 7.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 7.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 6.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 6.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 4.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 6.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 7.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 7.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 7.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 7.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 7.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 6.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 6.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 6.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 5.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 3.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 3.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 7.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 5.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 4.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 4.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 5.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 5.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 7.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 8.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 8.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 8.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 8.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 3.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 2.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": -0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 3.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 5.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 5.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 4.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 3.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 2.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": -0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": -1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": -0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 4.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": -0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": -0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": -0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": -2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": -2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": -0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 5.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 7.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 8.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 8.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 3.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 2.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": -0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": -0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": -0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": -0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": -0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": -1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": -0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 3.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 3.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 4.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 5.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 7.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 8.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 8.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 4.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": -1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": -1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": -2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": -2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 4.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 8.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 8.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": -1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": -2.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                }
            ],
            "AVERAGE": [
                {
                    "x": 1737932400000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 7.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 7.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 8.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 8.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 10.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 12.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 13.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 13.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 13.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 13,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 12.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 12.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 11.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 11.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 11.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 10.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 9.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 8.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 3.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 3.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 7.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 7.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 2.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 7.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 5.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 4.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 4.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 3.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 2.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 4.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 2.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 4.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 6.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 7.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 8.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 8.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 7.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 6.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 4.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": -0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 2.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 4.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 3.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 3.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 3.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": -0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": -1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": -1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": -2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": -1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 2.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 6.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 4.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 2.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": -0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": -2.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": -1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": -2.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": -2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": -2.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": -2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": -2.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 2.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 7.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 8.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": -0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": -0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": -0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": -1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": -1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": -2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 4.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 6.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 7.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 7.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 8.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": -0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": -2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": -2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": -2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 7.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 7.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 3.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": -0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": -1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": -1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": -2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": -2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": -0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                }
            ]
        }
    },
    {
        "id": 24,
        "progresId": 26,
        "grouping": "HOUR",
        "sensorCategory": "RADIATION",
        "sensorType": "ANALOG",
        "series": {
            "MINIMUM": [
                {
                    "x": 1737932400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 13,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 65,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 100,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 160,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 99,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 54,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 63,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 26,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 13,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 100,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 300,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 386,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 282,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 152,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 147,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 186,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 125,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 13,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 90,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 202,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 278,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 235,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 189,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 187,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 169,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 44,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 1,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 153,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 236,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 225,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 221,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 132,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 60,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 121,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 221,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 17,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 30,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 218,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 188,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 305,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 477,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 398,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 315,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 211,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 19,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 16,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 54,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 65,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 112,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 188,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 219,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 301,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 201,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 19,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 26,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 68,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 103,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 130,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 126,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 190,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 217,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 160,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 21,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 1,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 50,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 84,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 158,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 154,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 243,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 276,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 313,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 206,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 22,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 1,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 68,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 101,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 198,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 244,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 367,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 428,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 314,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 211,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 23,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 3,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 150,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 305,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 426,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 502,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 512,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 439,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 310,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 208,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 26,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 3,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 83,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 212,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 393,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 498,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 523,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 440,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 306,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 205,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 31,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 4,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 84,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 312,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 115,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 115,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                }
            ],
            "MAXIMUM": [
                {
                    "x": 1737932400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 15,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 76,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 121,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 237,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 643,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 643,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 263,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 161,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 148,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 116,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 13,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 146,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 300,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 386,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 486,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 603,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 650,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 646,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 518,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 396,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 218,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 13,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 131,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 273,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 397,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 598,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 360,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 408,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 330,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 323,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 240,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 44,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 1,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 154,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 356,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 443,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 553,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 413,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 362,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 145,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 326,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 321,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 221,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 17,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 40,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 348,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 425,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 505,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 573,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 561,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 512,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 420,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 315,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 211,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 19,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 16,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 54,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 81,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 153,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 253,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 384,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 507,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 421,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 301,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 201,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 19,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 26,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 68,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 122,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 191,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 198,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 190,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 480,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 506,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 343,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 175,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 21,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 1,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 50,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 84,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 173,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 230,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 278,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 460,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 585,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 424,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 313,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 206,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 22,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 1,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 68,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 125,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 202,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 277,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 386,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 589,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 521,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 428,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 321,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 211,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 23,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 3,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 152,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 305,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 437,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 502,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 543,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 551,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 512,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 439,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 310,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 208,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 26,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 3,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 88,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 276,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 393,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 498,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 538,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 541,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 523,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 440,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 315,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 205,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 31,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 4,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 96,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 312,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 445,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 544,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 153,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                }
            ],
            "AVERAGE": [
                {
                    "x": 1737932400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 8,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 29,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 91,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 133,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 304,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 170,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 111,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 95,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 55,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 57,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 1,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 50,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 226,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 345,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 438,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 517,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 419,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 421,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 357,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 192,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 68,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 1,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 43,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 197,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 319,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 393,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 273,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 243,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 234,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 236,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 96,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 14,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 51,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 265,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 324,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 381,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 291,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 216,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 107,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 169,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 272,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 99,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 2,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 18,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 192,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 334,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 431,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 508,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 525,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 468,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 362,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 264,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 115,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 3,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 6,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 23,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 71,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 112,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 197,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 240,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 401,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 359,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 253,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 111,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 3,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 7,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 47,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 94,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 159,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 159,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 167,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 281,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 349,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 237,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 95,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 3,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 17,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 60,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 135,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 197,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 229,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 326,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 441,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 368,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 264,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 114,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 4,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 24,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 84,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 157,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 243,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 285,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 502,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 477,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 367,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 270,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 120,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 4,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 53,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 229,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 364,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 467,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 526,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 532,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 473,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 367,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 260,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 121,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 5,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 42,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 153,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 294,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 444,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 522,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 533,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 486,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 375,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 264,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 122,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 6,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 41,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 176,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 368,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 379,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 133,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                }
            ]
        }
    },
    {
        "id": 26,
        "progresId": 3,
        "grouping": "HOUR",
        "sensorCategory": "RAIN_GAUGE",
        "sensorType": "METER",
        "series": {
            "LEAK": [
                {
                    "x": 1737964200000,
                    "y": 0,
                    "unit": "CUBIC_METERS",
                    "symbol": null,
                    "additionalInfo": null
                },
                {
                    "x": 1737966000000,
                    "y": 0,
                    "unit": "CUBIC_METERS",
                    "symbol": null,
                    "additionalInfo": null
                },
                {
                    "x": 1737970200000,
                    "y": 0,
                    "unit": "CUBIC_METERS",
                    "symbol": null,
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 0,
                    "unit": "CUBIC_METERS",
                    "symbol": null,
                    "additionalInfo": null
                }
            ],
            "VOLUME": [
                {
                    "x": 1737964200000,
                    "y": 20,
                    "unit": "CUBIC_METERS",
                    "symbol": null,
                    "additionalInfo": null
                },
                {
                    "x": 1737966000000,
                    "y": 40,
                    "unit": "CUBIC_METERS",
                    "symbol": null,
                    "additionalInfo": null
                },
                {
                    "x": 1737970200000,
                    "y": 40,
                    "unit": "CUBIC_METERS",
                    "symbol": null,
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 20,
                    "unit": "CUBIC_METERS",
                    "symbol": null,
                    "additionalInfo": null
                }
            ]
        }
    }
]

export const MOCK_DPV_DATA = {
    "id": 1,
    "grouping": "HOUR",
    "series": {
        "DPV": [
            {
                "x": 1737932400000,
                "y": 0.23,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737936000000,
                "y": 0.21,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737939600000,
                "y": 0.17,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737943200000,
                "y": 0.24,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737946800000,
                "y": 0.27,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737950400000,
                "y": 0.3,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737954000000,
                "y": 0.26,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737957600000,
                "y": 0.28,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737961200000,
                "y": 0.33,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737964800000,
                "y": 0.3,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737968400000,
                "y": 0.22,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737972000000,
                "y": 0.23,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737975600000,
                "y": 0.28,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737979200000,
                "y": 0.34,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737982800000,
                "y": 0.45,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737986400000,
                "y": 0.4,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737990000000,
                "y": 0.36,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737993600000,
                "y": 0.33,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737997200000,
                "y": 0.33,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738000800000,
                "y": 0.18,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738004400000,
                "y": 0.16,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738008000000,
                "y": 0.3,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738011600000,
                "y": 0.38,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738015200000,
                "y": 0.3,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738018800000,
                "y": 0.3,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738022400000,
                "y": 0.4,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738026000000,
                "y": 0.4,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738029600000,
                "y": 0.46,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738033200000,
                "y": 0.43,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738036800000,
                "y": 0.4,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738040400000,
                "y": 0.33,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738044000000,
                "y": 0.27,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738047600000,
                "y": 0.23,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738051200000,
                "y": 0.24,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738054800000,
                "y": 0.38,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738058400000,
                "y": 0.49,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738062000000,
                "y": 0.55,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738065600000,
                "y": 0.69,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738069200000,
                "y": 0.79,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738072800000,
                "y": 0.82,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738076400000,
                "y": 0.92,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738080000000,
                "y": 0.8,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738083600000,
                "y": 0.69,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738087200000,
                "y": 0.6,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738090800000,
                "y": 0.57,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738094400000,
                "y": 0.52,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738098000000,
                "y": 0.46,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738101600000,
                "y": 0.41,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738105200000,
                "y": 0.38,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738108800000,
                "y": 0.33,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738112400000,
                "y": 0.28,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738116000000,
                "y": 0.28,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738119600000,
                "y": 0.24,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738123200000,
                "y": 0.16,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738126800000,
                "y": 0.14,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738130400000,
                "y": 0.17,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738134000000,
                "y": 0.2,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738137600000,
                "y": 0.23,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738141200000,
                "y": 0.35,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738144800000,
                "y": 0.39,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738148400000,
                "y": 0.47,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738152000000,
                "y": 0.51,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738155600000,
                "y": 0.46,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738159200000,
                "y": 0.55,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738162800000,
                "y": 0.62,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738166400000,
                "y": 0.59,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738170000000,
                "y": 0.5,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738173600000,
                "y": 0.43,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738177200000,
                "y": 0.39,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738180800000,
                "y": 0.31,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738184400000,
                "y": 0.27,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738188000000,
                "y": 0.31,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738191600000,
                "y": 0.21,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738195200000,
                "y": 0.19,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738198800000,
                "y": 0.18,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738202400000,
                "y": 0.18,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738206000000,
                "y": 0.14,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738209600000,
                "y": 0.11,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738213200000,
                "y": 0.11,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738216800000,
                "y": 0.1,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738220400000,
                "y": 0.1,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738224000000,
                "y": 0.12,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738227600000,
                "y": 0.2,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738231200000,
                "y": 0.25,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738234800000,
                "y": 0.34,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738238400000,
                "y": 0.35,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738242000000,
                "y": 0.37,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738245600000,
                "y": 0.34,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738249200000,
                "y": 0.33,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738252800000,
                "y": 0.43,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738256400000,
                "y": 0.38,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738260000000,
                "y": 0.27,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738263600000,
                "y": 0.21,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738267200000,
                "y": 0.16,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738270800000,
                "y": 0.2,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738274400000,
                "y": 0.17,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738278000000,
                "y": 0.16,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738281600000,
                "y": 0.16,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738285200000,
                "y": 0.17,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738288800000,
                "y": 0.14,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738292400000,
                "y": 0.11,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738296000000,
                "y": 0.06,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738299600000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738303200000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738306800000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738310400000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738314000000,
                "y": 0.11,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738317600000,
                "y": 0.23,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738321200000,
                "y": 0.29,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738324800000,
                "y": 0.39,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738328400000,
                "y": 0.49,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738332000000,
                "y": 0.6,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738335600000,
                "y": 0.7,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738339200000,
                "y": 0.75,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738342800000,
                "y": 0.67,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738346400000,
                "y": 0.5,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738350000000,
                "y": 0.4,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738353600000,
                "y": 0.33,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738357200000,
                "y": 0.3,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738360800000,
                "y": 0.3,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738364400000,
                "y": 0.29,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738368000000,
                "y": 0.25,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738371600000,
                "y": 0.21,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738375200000,
                "y": 0.13,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738378800000,
                "y": 0.11,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738382400000,
                "y": 0.13,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738386000000,
                "y": 0.11,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738389600000,
                "y": 0.09,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738393200000,
                "y": 0.09,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738396800000,
                "y": 0.11,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738400400000,
                "y": 0.12,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738404000000,
                "y": 0.16,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738407600000,
                "y": 0.18,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738411200000,
                "y": 0.22,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738414800000,
                "y": 0.28,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738418400000,
                "y": 0.33,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738422000000,
                "y": 0.44,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738425600000,
                "y": 0.42,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738429200000,
                "y": 0.32,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738432800000,
                "y": 0.19,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738436400000,
                "y": 0.17,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738440000000,
                "y": 0.17,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738443600000,
                "y": 0.15,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738447200000,
                "y": 0.11,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738450800000,
                "y": 0.08,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738454400000,
                "y": 0.06,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738458000000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738461600000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738465200000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738468800000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738472400000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738476000000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738479600000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738483200000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738486800000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738490400000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738494000000,
                "y": 0.06,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738497600000,
                "y": 0.08,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738501200000,
                "y": 0.1,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738504800000,
                "y": 0.17,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738508400000,
                "y": 0.32,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738512000000,
                "y": 0.35,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738515600000,
                "y": 0.34,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738519200000,
                "y": 0.15,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738522800000,
                "y": 0.1,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738526400000,
                "y": 0.08,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738530000000,
                "y": 0.07,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738533600000,
                "y": 0.06,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738537200000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738540800000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738544400000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738548000000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738551600000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738555200000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738558800000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738562400000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738566000000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738569600000,
                "y": 0.02,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738573200000,
                "y": 0.02,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738576800000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738580400000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738584000000,
                "y": 0.09,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738587600000,
                "y": 0.15,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738591200000,
                "y": 0.23,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738594800000,
                "y": 0.34,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738598400000,
                "y": 0.36,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738602000000,
                "y": 0.34,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738605600000,
                "y": 0.18,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738609200000,
                "y": 0.12,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738612800000,
                "y": 0.1,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738616400000,
                "y": 0.07,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738620000000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738623600000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738627200000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738630800000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738634400000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738638000000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738641600000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738645200000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738648800000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738652400000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738656000000,
                "y": 0.02,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738659600000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738663200000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738666800000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738670400000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738674000000,
                "y": 0.08,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738677600000,
                "y": 0.22,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738681200000,
                "y": 0.36,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738684800000,
                "y": 0.47,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738688400000,
                "y": 0.46,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738692000000,
                "y": 0.19,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738695600000,
                "y": 0.11,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738699200000,
                "y": 0.1,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738702800000,
                "y": 0.07,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738706400000,
                "y": 0.06,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738710000000,
                "y": 0.06,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738713600000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738717200000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738720800000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738724400000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738728000000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738731600000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738735200000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738738800000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738742400000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738746000000,
                "y": 0.12,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738749600000,
                "y": 0.13,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738753200000,
                "y": 0.14,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738756800000,
                "y": 0.18,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738760400000,
                "y": 0.25,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738764000000,
                "y": 0.32,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738767600000,
                "y": 0.4,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738771200000,
                "y": 0.47,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738774800000,
                "y": 0.52,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738778400000,
                "y": 0.26,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738782000000,
                "y": 0.16,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738785600000,
                "y": 0.12,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738789200000,
                "y": 0.09,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738792800000,
                "y": 0.08,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738796400000,
                "y": 0.07,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738800000000,
                "y": 0.07,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738803600000,
                "y": 0.06,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738807200000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738810800000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738814400000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738818000000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738821600000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738825200000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738828800000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738832400000,
                "y": 0.06,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738836000000,
                "y": 0.08,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738839600000,
                "y": 0.16,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738843200000,
                "y": 0.2,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738846800000,
                "y": 0.27,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738850400000,
                "y": 0.36,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738854000000,
                "y": 0.42,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738857600000,
                "y": 0.52,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738861200000,
                "y": 0.53,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738864800000,
                "y": 0.29,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738868400000,
                "y": 0.17,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738872000000,
                "y": 0.1,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738875600000,
                "y": 0.08,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738879200000,
                "y": 0.07,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738882800000,
                "y": 0.06,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738886400000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738890000000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738893600000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738897200000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738900800000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738904400000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738908000000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738911600000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738915200000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738918800000,
                "y": 0.06,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738922400000,
                "y": 0.17,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738926000000,
                "y": 0.3,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738926600000,
                "y": 0.34,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            }
        ]
    }
}

export const MOCK_ETO_DATA = {
    "id": 2,
    "grouping": "DAY",
    "series": {
        "ETO": [
            {
                "x": 1738018799000,
                "y": 0.61,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738105199000,
                "y": 1.92,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738191599000,
                "y": 1.09,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738277999000,
                "y": 1.17,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738364399000,
                "y": 1.66,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738450799000,
                "y": 0.77,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738537199000,
                "y": 0.74,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738623599000,
                "y": 0.95,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738709999000,
                "y": 1.08,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738796399000,
                "y": 1.52,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738882799000,
                "y": 1.41,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738925999000,
                "y": 0.41,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            }
        ],
        "ACCUMULATED": [
            {
                "x": 1738018799000,
                "y": 0.61,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738105199000,
                "y": 2.53,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738191599000,
                "y": 3.62,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738277999000,
                "y": 4.79,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738364399000,
                "y": 6.45,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738450799000,
                "y": 7.22,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738537199000,
                "y": 7.96,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738623599000,
                "y": 8.91,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738709999000,
                "y": 9.99,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738796399000,
                "y": 11.51,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738882799000,
                "y": 12.92,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738925999000,
                "y": 13.33,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            }
        ]
    }
}

export const MOCK_ANTI_FROST_SURV = {
    "id": 3,
    "grouping": "HOUR",
    "series": {
        "DEW_POINT": [
            {
                "x": 1737932400000,
                "y": 5.37,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737932400000,
                "y": 5.37,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737936000000,
                "y": 5.21,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737936000000,
                "y": 5.21,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737939600000,
                "y": 5.49,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737939600000,
                "y": 5.49,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737943200000,
                "y": 5.19,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737943200000,
                "y": 5.19,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737946800000,
                "y": 4.99,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737946800000,
                "y": 4.99,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737950400000,
                "y": 5.53,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737950400000,
                "y": 5.53,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737954000000,
                "y": 5.85,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737954000000,
                "y": 5.85,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737957600000,
                "y": 6.16,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737957600000,
                "y": 6.16,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737961200000,
                "y": 6.69,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737961200000,
                "y": 6.69,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737964800000,
                "y": 7.68,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737964800000,
                "y": 7.68,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737968400000,
                "y": 9.14,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737968400000,
                "y": 9.14,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737972000000,
                "y": 11.19,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737972000000,
                "y": 11.19,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737975600000,
                "y": 12.42,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737975600000,
                "y": 12.42,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737979200000,
                "y": 12.23,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737979200000,
                "y": 12.23,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737982800000,
                "y": 11.94,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737982800000,
                "y": 11.94,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737986400000,
                "y": 11.56,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737986400000,
                "y": 11.56,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737990000000,
                "y": 11.41,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737990000000,
                "y": 11.41,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737993600000,
                "y": 11.43,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737993600000,
                "y": 11.43,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737997200000,
                "y": 11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737997200000,
                "y": 11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738000800000,
                "y": 10.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738000800000,
                "y": 10.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738004400000,
                "y": 10.22,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738004400000,
                "y": 10.22,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738008000000,
                "y": 8.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738008000000,
                "y": 8.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738011600000,
                "y": 7.63,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738011600000,
                "y": 7.63,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738015200000,
                "y": 6.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738015200000,
                "y": 6.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738018800000,
                "y": 5.02,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738018800000,
                "y": 5.02,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738022400000,
                "y": 3.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738022400000,
                "y": 3.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738026000000,
                "y": 2.76,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738026000000,
                "y": 2.76,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738029600000,
                "y": 2.35,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738029600000,
                "y": 2.35,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738033200000,
                "y": 2.13,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738033200000,
                "y": 2.13,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738036800000,
                "y": 1.96,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738036800000,
                "y": 1.96,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738040400000,
                "y": 1.72,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738040400000,
                "y": 1.72,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738044000000,
                "y": 1.35,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738044000000,
                "y": 1.35,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738047600000,
                "y": 1.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738047600000,
                "y": 1.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738051200000,
                "y": 2.35,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738051200000,
                "y": 2.35,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738054800000,
                "y": 2.93,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738054800000,
                "y": 2.93,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738058400000,
                "y": 2.84,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738058400000,
                "y": 2.84,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738062000000,
                "y": 2.32,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738062000000,
                "y": 2.32,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738065600000,
                "y": 1.15,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738065600000,
                "y": 1.15,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738069200000,
                "y": 0.98,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738069200000,
                "y": 0.98,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738072800000,
                "y": 0.41,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738072800000,
                "y": 0.41,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738076400000,
                "y": 1.08,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738076400000,
                "y": 1.08,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738080000000,
                "y": 1.54,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738080000000,
                "y": 1.54,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738083600000,
                "y": 1.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738083600000,
                "y": 1.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738087200000,
                "y": 1.87,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738087200000,
                "y": 1.87,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738090800000,
                "y": 2.36,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738090800000,
                "y": 2.36,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738094400000,
                "y": 2.66,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738094400000,
                "y": 2.66,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738098000000,
                "y": 2.72,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738098000000,
                "y": 2.72,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738101600000,
                "y": 2.79,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738101600000,
                "y": 2.79,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738105200000,
                "y": 2.84,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738105200000,
                "y": 2.84,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738108800000,
                "y": 2.92,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738108800000,
                "y": 2.92,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738112400000,
                "y": 2.61,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738112400000,
                "y": 2.61,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738116000000,
                "y": 2.03,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738116000000,
                "y": 2.03,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738119600000,
                "y": 1.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738119600000,
                "y": 1.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738123200000,
                "y": 1.04,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738123200000,
                "y": 1.04,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738126800000,
                "y": 1.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738126800000,
                "y": 1.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738130400000,
                "y": 1.46,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738130400000,
                "y": 1.46,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738134000000,
                "y": 1.46,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738134000000,
                "y": 1.46,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738137600000,
                "y": 2.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738137600000,
                "y": 2.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738141200000,
                "y": 2.77,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738141200000,
                "y": 2.77,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738144800000,
                "y": 2.99,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738144800000,
                "y": 2.99,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738148400000,
                "y": 3.11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738148400000,
                "y": 3.11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738152000000,
                "y": 3.61,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738152000000,
                "y": 3.61,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738155600000,
                "y": 3.04,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738155600000,
                "y": 3.04,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738159200000,
                "y": 2.57,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738159200000,
                "y": 2.57,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738162800000,
                "y": 2.13,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738162800000,
                "y": 2.13,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738166400000,
                "y": 2.32,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738166400000,
                "y": 2.32,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738170000000,
                "y": 2.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738170000000,
                "y": 2.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738173600000,
                "y": 2.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738173600000,
                "y": 2.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738177200000,
                "y": 3.36,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738177200000,
                "y": 3.36,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738180800000,
                "y": 3.43,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738180800000,
                "y": 3.43,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738184400000,
                "y": 3.37,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738184400000,
                "y": 3.37,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738188000000,
                "y": 3.17,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738188000000,
                "y": 3.17,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738191600000,
                "y": 2.85,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738191600000,
                "y": 2.85,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738195200000,
                "y": 3.02,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738195200000,
                "y": 3.02,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738198800000,
                "y": 2.87,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738198800000,
                "y": 2.87,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738202400000,
                "y": 2.49,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738202400000,
                "y": 2.49,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738206000000,
                "y": 2.08,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738206000000,
                "y": 2.08,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738209600000,
                "y": 2.19,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738209600000,
                "y": 2.19,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738213200000,
                "y": 2.11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738213200000,
                "y": 2.11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738216800000,
                "y": 1.94,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738216800000,
                "y": 1.94,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738220400000,
                "y": 1.99,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738220400000,
                "y": 1.99,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738224000000,
                "y": 3.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738224000000,
                "y": 3.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738227600000,
                "y": 3.17,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738227600000,
                "y": 3.17,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738231200000,
                "y": 3.38,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738231200000,
                "y": 3.38,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738234800000,
                "y": 3.69,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738234800000,
                "y": 3.69,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738238400000,
                "y": 4.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738238400000,
                "y": 4.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738242000000,
                "y": 4.25,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738242000000,
                "y": 4.25,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738245600000,
                "y": 4.62,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738245600000,
                "y": 4.62,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738249200000,
                "y": 4.73,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738249200000,
                "y": 4.73,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738252800000,
                "y": 4.47,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738252800000,
                "y": 4.47,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738256400000,
                "y": 3.72,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738256400000,
                "y": 3.72,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738260000000,
                "y": 3.61,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738260000000,
                "y": 3.61,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738263600000,
                "y": 3.11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738263600000,
                "y": 3.11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738267200000,
                "y": 3.38,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738267200000,
                "y": 3.38,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738270800000,
                "y": 2.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738270800000,
                "y": 2.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738274400000,
                "y": 2.53,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738274400000,
                "y": 2.53,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738278000000,
                "y": 2.45,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738278000000,
                "y": 2.45,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738281600000,
                "y": 2.34,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738281600000,
                "y": 2.34,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738285200000,
                "y": 2.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738285200000,
                "y": 2.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738288800000,
                "y": 1.59,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738288800000,
                "y": 1.59,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738292400000,
                "y": 0.22,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738292400000,
                "y": 0.22,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738296000000,
                "y": -0.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738296000000,
                "y": -0.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738299600000,
                "y": 0.21,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738299600000,
                "y": 0.21,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738303200000,
                "y": -0.39,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738303200000,
                "y": -0.39,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738306800000,
                "y": -0.26,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738306800000,
                "y": -0.26,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738310400000,
                "y": 1.13,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738310400000,
                "y": 1.13,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738314000000,
                "y": 2.69,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738314000000,
                "y": 2.69,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738317600000,
                "y": 3.06,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738317600000,
                "y": 3.06,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738321200000,
                "y": 3.17,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738321200000,
                "y": 3.17,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738324800000,
                "y": 3.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738324800000,
                "y": 3.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738328400000,
                "y": 3.13,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738328400000,
                "y": 3.13,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738332000000,
                "y": 3.06,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738332000000,
                "y": 3.06,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738335600000,
                "y": 2.82,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738335600000,
                "y": 2.82,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738339200000,
                "y": 2.75,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738339200000,
                "y": 2.75,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738342800000,
                "y": 2.03,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738342800000,
                "y": 2.03,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738346400000,
                "y": 1.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738346400000,
                "y": 1.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738350000000,
                "y": 0.81,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738350000000,
                "y": 0.81,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738353600000,
                "y": 0.19,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738353600000,
                "y": 0.19,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738357200000,
                "y": -0.29,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738357200000,
                "y": -0.29,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738360800000,
                "y": -0.66,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738360800000,
                "y": -0.66,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738364400000,
                "y": -0.94,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738364400000,
                "y": -0.94,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738368000000,
                "y": -1.36,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738368000000,
                "y": -1.36,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738371600000,
                "y": -2.11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738371600000,
                "y": -2.11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738375200000,
                "y": -2.28,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738375200000,
                "y": -2.28,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738378800000,
                "y": -1.61,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738378800000,
                "y": -1.61,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738382400000,
                "y": -1.61,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738382400000,
                "y": -1.61,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738386000000,
                "y": -1.93,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738386000000,
                "y": -1.93,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738389600000,
                "y": -1.75,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738389600000,
                "y": -1.75,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738393200000,
                "y": -1.26,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738393200000,
                "y": -1.26,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738396800000,
                "y": -0.69,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738396800000,
                "y": -0.69,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738400400000,
                "y": 0.18,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738400400000,
                "y": 0.18,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738404000000,
                "y": 0.85,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738404000000,
                "y": 0.85,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738407600000,
                "y": 1.11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738407600000,
                "y": 1.11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738411200000,
                "y": 1.28,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738411200000,
                "y": 1.28,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738414800000,
                "y": 1.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738414800000,
                "y": 1.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738418400000,
                "y": 1.19,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738418400000,
                "y": 1.19,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738422000000,
                "y": 1.66,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738422000000,
                "y": 1.66,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738425600000,
                "y": 2.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738425600000,
                "y": 2.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738429200000,
                "y": 1.79,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738429200000,
                "y": 1.79,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738432800000,
                "y": 2.06,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738432800000,
                "y": 2.06,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738436400000,
                "y": 1.89,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738436400000,
                "y": 1.89,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738440000000,
                "y": 1.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738440000000,
                "y": 1.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738443600000,
                "y": 1.31,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738443600000,
                "y": 1.31,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738447200000,
                "y": 0.71,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738447200000,
                "y": 0.71,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738450800000,
                "y": 0.23,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738450800000,
                "y": 0.23,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738454400000,
                "y": -0.63,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738454400000,
                "y": -0.63,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738458000000,
                "y": -0.61,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738458000000,
                "y": -0.61,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738461600000,
                "y": -0.46,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738461600000,
                "y": -0.46,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738465200000,
                "y": -0.46,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738465200000,
                "y": -0.46,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738468800000,
                "y": -0.08,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738468800000,
                "y": -0.08,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738472400000,
                "y": 0.02,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738472400000,
                "y": 0.02,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738476000000,
                "y": 0.26,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738476000000,
                "y": 0.26,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738479600000,
                "y": 0.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738479600000,
                "y": 0.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738483200000,
                "y": 0.83,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738483200000,
                "y": 0.83,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738486800000,
                "y": 1.68,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738486800000,
                "y": 1.68,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738490400000,
                "y": 1.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738490400000,
                "y": 1.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738494000000,
                "y": 1.83,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738494000000,
                "y": 1.83,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738497600000,
                "y": 2.06,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738497600000,
                "y": 2.06,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738501200000,
                "y": 2.35,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738501200000,
                "y": 2.35,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738504800000,
                "y": 2.99,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738504800000,
                "y": 2.99,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738508400000,
                "y": 3.05,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738508400000,
                "y": 3.05,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738512000000,
                "y": 2.83,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738512000000,
                "y": 2.83,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738515600000,
                "y": 1.66,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738515600000,
                "y": 1.66,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738519200000,
                "y": 1.08,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738519200000,
                "y": 1.08,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738522800000,
                "y": 0.38,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738522800000,
                "y": 0.38,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738526400000,
                "y": 0.33,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738526400000,
                "y": 0.33,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738530000000,
                "y": -0.36,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738530000000,
                "y": -0.36,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738533600000,
                "y": -1.32,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738533600000,
                "y": -1.32,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738537200000,
                "y": -2.03,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738537200000,
                "y": -2.03,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738540800000,
                "y": -2.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738540800000,
                "y": -2.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738544400000,
                "y": -2.04,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738544400000,
                "y": -2.04,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738548000000,
                "y": -2.12,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738548000000,
                "y": -2.12,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738551600000,
                "y": -2.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738551600000,
                "y": -2.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738555200000,
                "y": -3.02,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738555200000,
                "y": -3.02,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738558800000,
                "y": -2.17,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738558800000,
                "y": -2.17,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738562400000,
                "y": -2.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738562400000,
                "y": -2.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738566000000,
                "y": -2.46,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738566000000,
                "y": -2.46,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738569600000,
                "y": -1.11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738569600000,
                "y": -1.11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738573200000,
                "y": 0.29,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738573200000,
                "y": 0.29,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738576800000,
                "y": 1.52,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738576800000,
                "y": 1.52,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738580400000,
                "y": 1.97,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738580400000,
                "y": 1.97,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738584000000,
                "y": 2.68,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738584000000,
                "y": 2.68,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738587600000,
                "y": 3.03,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738587600000,
                "y": 3.03,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738591200000,
                "y": 3.63,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738591200000,
                "y": 3.63,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738594800000,
                "y": 3.71,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738594800000,
                "y": 3.71,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738598400000,
                "y": 3.45,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738598400000,
                "y": 3.45,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738602000000,
                "y": 2.51,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738602000000,
                "y": 2.51,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738605600000,
                "y": 1.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738605600000,
                "y": 1.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738609200000,
                "y": 1.16,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738609200000,
                "y": 1.16,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738612800000,
                "y": 0.33,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738612800000,
                "y": 0.33,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738616400000,
                "y": -0.33,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738616400000,
                "y": -0.33,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738620000000,
                "y": -1.26,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738620000000,
                "y": -1.26,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            }
        ],
        "TEMPERATURE": [
            {
                "x": 1737932400000,
                "y": 8.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737936000000,
                "y": 7.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737939600000,
                "y": 9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737943200000,
                "y": 9.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737946800000,
                "y": 9.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737950400000,
                "y": 9.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737954000000,
                "y": 9.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737957600000,
                "y": 10.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737961200000,
                "y": 10.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737964800000,
                "y": 10.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737968400000,
                "y": 11.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737972000000,
                "y": 14.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737975600000,
                "y": 15.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737979200000,
                "y": 16.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737982800000,
                "y": 15.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737986400000,
                "y": 15.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737990000000,
                "y": 14.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737993600000,
                "y": 14.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737997200000,
                "y": 12.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738000800000,
                "y": 12.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738004400000,
                "y": 13.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738008000000,
                "y": 13,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738011600000,
                "y": 11.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738015200000,
                "y": 10.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738018800000,
                "y": 10.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738022400000,
                "y": 9.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738026000000,
                "y": 9.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738029600000,
                "y": 9.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738033200000,
                "y": 8.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738036800000,
                "y": 7.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738040400000,
                "y": 6.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738044000000,
                "y": 5.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738047600000,
                "y": 5.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738051200000,
                "y": 8.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738054800000,
                "y": 10.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738058400000,
                "y": 11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738062000000,
                "y": 12.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738065600000,
                "y": 12.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738069200000,
                "y": 13,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738072800000,
                "y": 13.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738076400000,
                "y": 12.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738080000000,
                "y": 11.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738083600000,
                "y": 10.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738087200000,
                "y": 10.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738090800000,
                "y": 10.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738094400000,
                "y": 9.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738098000000,
                "y": 9.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738101600000,
                "y": 8.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738105200000,
                "y": 8.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738108800000,
                "y": 7.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738112400000,
                "y": 7.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738116000000,
                "y": 6.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738119600000,
                "y": 4.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738123200000,
                "y": 3.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738126800000,
                "y": 4.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738130400000,
                "y": 5.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738134000000,
                "y": 5.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738137600000,
                "y": 7.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738141200000,
                "y": 9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738144800000,
                "y": 10.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738148400000,
                "y": 10.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738152000000,
                "y": 10.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738155600000,
                "y": 11.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738159200000,
                "y": 11.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738162800000,
                "y": 11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738166400000,
                "y": 10,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738170000000,
                "y": 9.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738173600000,
                "y": 8.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738177200000,
                "y": 8.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738180800000,
                "y": 7.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738184400000,
                "y": 8.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738188000000,
                "y": 6.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738191600000,
                "y": 6.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738195200000,
                "y": 6.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738198800000,
                "y": 5.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738202400000,
                "y": 5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738206000000,
                "y": 4.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738209600000,
                "y": 4.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738213200000,
                "y": 4.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738216800000,
                "y": 3.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738220400000,
                "y": 4.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738224000000,
                "y": 6.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738227600000,
                "y": 7.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738231200000,
                "y": 8.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738234800000,
                "y": 9.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738238400000,
                "y": 9.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738242000000,
                "y": 9.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738245600000,
                "y": 9.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738249200000,
                "y": 10.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738252800000,
                "y": 10.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738256400000,
                "y": 8.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738260000000,
                "y": 7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738263600000,
                "y": 5.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738267200000,
                "y": 6.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738270800000,
                "y": 5.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738274400000,
                "y": 5.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738278000000,
                "y": 5.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738281600000,
                "y": 5.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738285200000,
                "y": 4.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738288800000,
                "y": 3.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738292400000,
                "y": 1.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738296000000,
                "y": 0.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738299600000,
                "y": 1.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738303200000,
                "y": 0.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738306800000,
                "y": 0.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738310400000,
                "y": 3.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738314000000,
                "y": 6.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738317600000,
                "y": 7.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738321200000,
                "y": 9.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738324800000,
                "y": 10.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738328400000,
                "y": 11.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738332000000,
                "y": 12.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738335600000,
                "y": 13.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738339200000,
                "y": 12.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738342800000,
                "y": 9.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738346400000,
                "y": 7.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738350000000,
                "y": 6.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738353600000,
                "y": 5.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738357200000,
                "y": 5.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738360800000,
                "y": 5.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738364400000,
                "y": 4.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738368000000,
                "y": 3.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738371600000,
                "y": 0.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738375200000,
                "y": 0.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738378800000,
                "y": 1.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738382400000,
                "y": 0.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738386000000,
                "y": 0.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738389600000,
                "y": 0.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738393200000,
                "y": 1.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738396800000,
                "y": 1.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738400400000,
                "y": 3.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738404000000,
                "y": 4.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738407600000,
                "y": 5.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738411200000,
                "y": 6.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738414800000,
                "y": 7.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738418400000,
                "y": 8.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738422000000,
                "y": 8.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738425600000,
                "y": 7.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738429200000,
                "y": 5.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738432800000,
                "y": 5.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738436400000,
                "y": 5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738440000000,
                "y": 4.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738443600000,
                "y": 3.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738447200000,
                "y": 2.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738450800000,
                "y": 1.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738454400000,
                "y": 0.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738458000000,
                "y": 0.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738461600000,
                "y": 0.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738465200000,
                "y": 0.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738468800000,
                "y": 1.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738472400000,
                "y": 1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738476000000,
                "y": 1.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738479600000,
                "y": 1.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738483200000,
                "y": 1.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738486800000,
                "y": 2.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738490400000,
                "y": 3.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738494000000,
                "y": 3.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738497600000,
                "y": 4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738501200000,
                "y": 5.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738504800000,
                "y": 8.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738508400000,
                "y": 8.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738512000000,
                "y": 8.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738515600000,
                "y": 4.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738519200000,
                "y": 3.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738522800000,
                "y": 2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738526400000,
                "y": 1.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738530000000,
                "y": 1.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738533600000,
                "y": -0.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738537200000,
                "y": -0.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738540800000,
                "y": -1.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738544400000,
                "y": -1.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738548000000,
                "y": -1.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738551600000,
                "y": -1.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738555200000,
                "y": -2.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738558800000,
                "y": -1.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738562400000,
                "y": -1.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738566000000,
                "y": -1.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738569600000,
                "y": -0.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738573200000,
                "y": 0.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738576800000,
                "y": 2.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738580400000,
                "y": 3.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738584000000,
                "y": 5.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738587600000,
                "y": 6.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738591200000,
                "y": 9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738594800000,
                "y": 9.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738598400000,
                "y": 8.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738602000000,
                "y": 5.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738605600000,
                "y": 3.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738609200000,
                "y": 3.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738612800000,
                "y": 1.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738616400000,
                "y": 0.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738620000000,
                "y": -0.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            }
        ],
        "WET_BULB": [
            {
                "x": 1737932400000,
                "y": 6.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737936000000,
                "y": 6.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737939600000,
                "y": 7.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737943200000,
                "y": 7.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737946800000,
                "y": 7.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737950400000,
                "y": 7.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737954000000,
                "y": 7.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737957600000,
                "y": 8.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737961200000,
                "y": 8.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737964800000,
                "y": 9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737968400000,
                "y": 10.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737972000000,
                "y": 12.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737975600000,
                "y": 13.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737979200000,
                "y": 13.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737982800000,
                "y": 13.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737986400000,
                "y": 13,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737990000000,
                "y": 12.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737993600000,
                "y": 12.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737997200000,
                "y": 11.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738000800000,
                "y": 11.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738004400000,
                "y": 11.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738008000000,
                "y": 10.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738011600000,
                "y": 9.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738015200000,
                "y": 8.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738018800000,
                "y": 7.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738022400000,
                "y": 6.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738026000000,
                "y": 6.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738029600000,
                "y": 6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738033200000,
                "y": 5.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738036800000,
                "y": 5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738040400000,
                "y": 4.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738044000000,
                "y": 3.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738047600000,
                "y": 3.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738051200000,
                "y": 5.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738054800000,
                "y": 6.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738058400000,
                "y": 7.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738062000000,
                "y": 7.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738065600000,
                "y": 7.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738069200000,
                "y": 7.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738072800000,
                "y": 7.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738076400000,
                "y": 7.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738080000000,
                "y": 7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738083600000,
                "y": 6.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738087200000,
                "y": 6.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738090800000,
                "y": 6.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738094400000,
                "y": 6.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738098000000,
                "y": 6.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738101600000,
                "y": 6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738105200000,
                "y": 5.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738108800000,
                "y": 5.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738112400000,
                "y": 5.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738116000000,
                "y": 4.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738119600000,
                "y": 2.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738123200000,
                "y": 2.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738126800000,
                "y": 3.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738130400000,
                "y": 3.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738134000000,
                "y": 3.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738137600000,
                "y": 5.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738141200000,
                "y": 6.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738144800000,
                "y": 6.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738148400000,
                "y": 7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738152000000,
                "y": 7.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738155600000,
                "y": 7.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738159200000,
                "y": 7.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738162800000,
                "y": 6.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738166400000,
                "y": 6.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738170000000,
                "y": 6.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738173600000,
                "y": 6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738177200000,
                "y": 5.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738180800000,
                "y": 5.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738184400000,
                "y": 6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738188000000,
                "y": 5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738191600000,
                "y": 4.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738195200000,
                "y": 4.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738198800000,
                "y": 4.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738202400000,
                "y": 3.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738206000000,
                "y": 3.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738209600000,
                "y": 3.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738213200000,
                "y": 3.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738216800000,
                "y": 2.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738220400000,
                "y": 3.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738224000000,
                "y": 4.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738227600000,
                "y": 5.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738231200000,
                "y": 6.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738234800000,
                "y": 6.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738238400000,
                "y": 6.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738242000000,
                "y": 6.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738245600000,
                "y": 7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738249200000,
                "y": 7.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738252800000,
                "y": 7.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738256400000,
                "y": 6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738260000000,
                "y": 5.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738263600000,
                "y": 4.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738267200000,
                "y": 5.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738270800000,
                "y": 4.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738274400000,
                "y": 4.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738278000000,
                "y": 4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738281600000,
                "y": 4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738285200000,
                "y": 3.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738288800000,
                "y": 2.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738292400000,
                "y": 0.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738296000000,
                "y": 0.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738299600000,
                "y": 0.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738303200000,
                "y": 0.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738306800000,
                "y": 0.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738310400000,
                "y": 2.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738314000000,
                "y": 4.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738317600000,
                "y": 5.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738321200000,
                "y": 6.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738324800000,
                "y": 6.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738328400000,
                "y": 7.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738332000000,
                "y": 8.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738335600000,
                "y": 8.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738339200000,
                "y": 7.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738342800000,
                "y": 6.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738346400000,
                "y": 4.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738350000000,
                "y": 4.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738353600000,
                "y": 3.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738357200000,
                "y": 3.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738360800000,
                "y": 2.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738364400000,
                "y": 2.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738368000000,
                "y": 1.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738371600000,
                "y": -0.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738375200000,
                "y": -0.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738378800000,
                "y": 0.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738382400000,
                "y": 0,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738386000000,
                "y": -0.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738389600000,
                "y": -0.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738393200000,
                "y": 0.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738396800000,
                "y": 0.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738400400000,
                "y": 2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738404000000,
                "y": 2.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738407600000,
                "y": 3.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738411200000,
                "y": 4.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738414800000,
                "y": 4.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738418400000,
                "y": 5.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738422000000,
                "y": 5.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738425600000,
                "y": 5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738429200000,
                "y": 3.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738432800000,
                "y": 3.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738436400000,
                "y": 3.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738440000000,
                "y": 3.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738443600000,
                "y": 2.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738447200000,
                "y": 1.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738450800000,
                "y": 0.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738454400000,
                "y": 0,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738458000000,
                "y": 0,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738461600000,
                "y": 0.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738465200000,
                "y": 0.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738468800000,
                "y": 0.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738472400000,
                "y": 0.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738476000000,
                "y": 0.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738479600000,
                "y": 1.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738483200000,
                "y": 1.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738486800000,
                "y": 2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738490400000,
                "y": 2.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738494000000,
                "y": 2.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738497600000,
                "y": 3.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738501200000,
                "y": 3.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738504800000,
                "y": 5.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738508400000,
                "y": 6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738512000000,
                "y": 5.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738515600000,
                "y": 3.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738519200000,
                "y": 2.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738522800000,
                "y": 1.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738526400000,
                "y": 1.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738530000000,
                "y": 0.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738533600000,
                "y": -0.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738537200000,
                "y": -1.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738540800000,
                "y": -1.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738544400000,
                "y": -1.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738548000000,
                "y": -1.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738551600000,
                "y": -1.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738555200000,
                "y": -2.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738558800000,
                "y": -1.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738562400000,
                "y": -2.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738566000000,
                "y": -2.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738569600000,
                "y": -0.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738573200000,
                "y": 0.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738576800000,
                "y": 2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738580400000,
                "y": 2.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738584000000,
                "y": 4.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738587600000,
                "y": 5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738591200000,
                "y": 6.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738594800000,
                "y": 6.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738598400000,
                "y": 6.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738602000000,
                "y": 4.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738605600000,
                "y": 2.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738609200000,
                "y": 2.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738612800000,
                "y": 1.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738616400000,
                "y": 0.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738620000000,
                "y": -0.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            }
        ]
    }
}

export const MOCK_SOIL_CONTROL_POINT_DATA = {
    "id": 1,
    "name": "AgroSens Riego Aquacheck",
    "model": null,
    "packCode": null,
    "type": "SOIL",
    "sensorPack": null,
    "sensors": [
        {
            "id": 6,
            "deviceId": 10045,
            "sensorCategory": "VOLUMETRIC_WATER_CONTENT",
            "sensorType": "ANALOG",
            "sensorDepth": 60,
            "readings": {
                "currentValue": {
                    "value": 28.2,
                    "valueFormatted": "28.2",
                    "unit": "PERCENT",
                    "symbol": "%"
                }
            },
            "progresId": 5,
            "isCalculated": null
        },
        {
            "id": 8,
            "deviceId": 10045,
            "sensorCategory": "VOLUMETRIC_WATER_CONTENT",
            "sensorType": "ANALOG",
            "sensorDepth": 80,
            "readings": {
                "currentValue": {
                    "value": 30.5,
                    "valueFormatted": "30.5",
                    "unit": "PERCENT",
                    "symbol": "%"
                }
            },
            "progresId": 7,
            "isCalculated": null
        },
        {
            "id": 2,
            "deviceId": 10045,
            "sensorCategory": "VOLUMETRIC_WATER_CONTENT",
            "sensorType": "ANALOG",
            "sensorDepth": 20,
            "readings": {
                "currentValue": {
                    "value": 17.7,
                    "valueFormatted": "17.7",
                    "unit": "PERCENT",
                    "symbol": "%"
                }
            },
            "progresId": 1,
            "isCalculated": null
        },
        {
            "id": 5,
            "deviceId": 10045,
            "sensorCategory": "TEMPERATURE",
            "sensorType": "ANALOG",
            "sensorDepth": 40,
            "readings": {
                "currentValue": {
                    "value": 6.3,
                    "valueFormatted": "6.3",
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC"
                }
            },
            "progresId": 4,
            "isCalculated": null
        },
        {
            "id": 30,
            "deviceId": 10045,
            "sensorCategory": "RAIN_GAUGE",
            "sensorType": "METER",
            "sensorDepth": null,
            "readings": {
                "currentValue": {
                    "value": 0,
                    "valueFormatted": "0.0",
                    "unit": "CUBIC_METERS_PER_HOUR",
                    "symbol": null
                }
            },
            "progresId": 3,
            "isCalculated": null
        },
        {
            "id": 7,
            "deviceId": 10045,
            "sensorCategory": "TEMPERATURE",
            "sensorType": "ANALOG",
            "sensorDepth": 60,
            "readings": {
                "currentValue": {
                    "value": 7.2,
                    "valueFormatted": "7.2",
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC"
                }
            },
            "progresId": 6,
            "isCalculated": null
        },
        {
            "id": 4,
            "deviceId": 10045,
            "sensorCategory": "VOLUMETRIC_WATER_CONTENT",
            "sensorType": "ANALOG",
            "sensorDepth": 40,
            "readings": {
                "currentValue": {
                    "value": 26.6,
                    "valueFormatted": "26.6",
                    "unit": "PERCENT",
                    "symbol": "%"
                }
            },
            "progresId": 3,
            "isCalculated": null
        },
        {
            "id": 9,
            "deviceId": 10045,
            "sensorCategory": "TEMPERATURE",
            "sensorType": "ANALOG",
            "sensorDepth": 80,
            "readings": {
                "currentValue": {
                    "value": 7.8,
                    "valueFormatted": "7.8",
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC"
                }
            },
            "progresId": 8,
            "isCalculated": null
        },
        {
            "id": 33,
            "deviceId": 10046,
            "sensorCategory": "IRRIGATION_COUNTER",
            "sensorType": "METER",
            "sensorDepth": null,
            "readings": {
                "currentValue": {
                    "value": 0,
                    "valueFormatted": "0",
                    "unit": "LITERS_PER_HOUR",
                    "symbol": null
                }
            },
            "progresId": 8,
            "isCalculated": null
        },
        {
            "id": 3,
            "deviceId": 10045,
            "sensorCategory": "TEMPERATURE",
            "sensorType": "ANALOG",
            "sensorDepth": 20,
            "readings": {
                "currentValue": {
                    "value": 5.7,
                    "valueFormatted": "5.7",
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC"
                }
            },
            "progresId": 2,
            "isCalculated": null
        }
    ],
    "farmId": 1,
    "farmName": "La Serra",
    "geoJson": "{ \"type\": \"Feature\", \"properties\": {}, \"geometry\": { \"coordinates\": [ 0.8703896121940033, 41.61831965178598 ], \"type\": \"Point\" } }",
    "availableAgronomicVariables": [
        "AVAILABLE_WATER",
        "CAMPAIGN_IRRIGATION"
    ]
}

export const MOCK_AVAILABLE_WATER = {
    "id": 1,
    "deviceId": 10046,
    "sectorId": 34,
    "sectorName": "Sec. 34",
    "availableWaterSoilCapacity": 100,
    "availableWaterWiltingThreshold": 50,
    "status": "NOT_IRRIGATING",
    "manualStatus": "PROGRAMMED",
    "errorStatus": "NO_ERROR",
    "grouping": "TEN_MINUTES",
    "series": {
        "20": [
            {
                "x": 1738537200000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738537800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738538400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738539000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738539600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738540200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738540800000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738541400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738542000000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738542600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738543200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738543800000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738544400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738545000000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738545600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738546200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738546800000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738547400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738548000000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738548600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738549200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738549800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738550400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738551000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738551600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738552200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738552800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738553400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738554000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738554600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738555200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738555800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738556400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738557000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738557600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738558200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738558800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738559400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738560000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738560600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738561200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738561800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738562400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738563000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738563600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738564200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738564800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738565400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738566000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738566600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738567200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738567800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738568400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738569000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738569600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738570200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738570800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738571400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738572000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738572600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738573200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738573800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738574400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738575000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738575600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738576200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738576800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738577400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738578000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738578600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738579200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738579800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738580400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738581000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738581600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738582200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738582800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738583400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738584000000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738584600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738585200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738585800000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738586400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738587000000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738587600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738588200000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738588800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738589400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738590000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738590600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738591200000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738591800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738592400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738593000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738593600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738594200000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738594800000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738595400000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738596000000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738596600000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738597200000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738597800000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738598400000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738599000000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738599600000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738600200000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738600800000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738601400000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738602000000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738602600000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738603200000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738603800000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738604400000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738605000000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738605600000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738606200000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738606800000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738607400000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738608000000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738608600000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738609200000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738609800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738610400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738611000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738611600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738612200000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738612800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738613400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738614000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738614600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738615200000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738615800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738616400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738617000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738617600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738618200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738618800000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738619400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738620000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738620600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738621200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738621800000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738622400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738623000000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738623600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738624200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738624800000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738625400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738626000000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738626600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738627200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738627800000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738628400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738629000000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738629600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738630200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738630800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738631400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738632000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738632600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738633200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738633800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738634400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738635000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738635600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738636200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738636800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738637400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738638000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738638600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738639200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738639800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738640400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738641000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738641600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738642200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738642800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738643400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738644000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738644600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738645200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738645800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738646400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738647000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738647600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738648200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738648800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738649400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738650000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738650600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738651200000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738651800000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738652400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738653000000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738653600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738654200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738654800000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738655400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738656000000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738656600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738657200000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738657800000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738658400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738659000000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738659600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738660200000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738660800000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738661400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738662000000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738662600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738663200000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738663800000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738664400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738665000000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738665600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738666200000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738666800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738667400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738668000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738668600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738669200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738669800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738670400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738671000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738671600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738672200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738672800000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738673400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738674000000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738674600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738675200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738675800000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738676400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738677000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738677600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738678200000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738678800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738679400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738680000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738680600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738681200000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738681800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738682400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738683000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738683600000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738684200000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738684800000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738685400000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738686000000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738686600000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738687200000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738687800000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738688400000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738689000000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738689600000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738690200000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738690800000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738691400000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738692000000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738692600000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738693200000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738693800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738694400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738695000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738695600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738696200000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738696800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738697400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738698000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738698600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738699200000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738699800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738700400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738701000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738701600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738702200000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738702800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738703400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738704000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738704600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738705200000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738705800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738706400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738707000000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738707600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738708200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738708800000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738709400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738710000000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738710600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738711200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738711800000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738712400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738713000000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738713600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738714200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738714800000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738715400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738716000000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738716600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738717200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738717800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738718400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738719000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738719600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738720200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738720800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738721400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738722000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738722600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738723200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738723800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738724400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738725000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738725600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738726200000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738726800000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738727400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738728000000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738728600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738729200000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738729800000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738730400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738731000000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738731600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738732200000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738732800000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738733400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738734000000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738734600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738735200000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738735800000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738736400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738737000000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738737600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738738200000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738738800000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738739400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738740000000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738740600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738741200000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738741800000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738742400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738743000000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738743600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738744200000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738744800000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738745400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738746000000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738746600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738747200000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738747800000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738748400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738749000000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738749600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738750200000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738750800000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738751400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738752000000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738752600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738753200000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738753800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738754400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738755000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738755600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738756200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738756800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738757400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738758000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738758600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738759200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738759800000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738760400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738761000000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738761600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738762200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738762800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738763400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738764000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738764600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738765200000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738765800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738766400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738767000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738767600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738768200000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738768800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738769400000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738770000000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738770600000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738771200000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738771800000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738772400000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738773000000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738773600000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738774200000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738774800000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738775400000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738776000000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738776600000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738777200000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738777800000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738778400000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738779000000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738779600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738780200000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738780800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738781400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738782000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738782600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738783200000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738783800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738784400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738785000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738785600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738786200000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738786800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738787400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738788000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738788600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738789200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738789800000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738790400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738791000000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738791600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738792200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738792800000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738793400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738794000000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738794600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738795200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738795800000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738796400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738797000000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738797600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738798200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738798800000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738799400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738800000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738800600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738801200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738801800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738802400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738803000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738803600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738804200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738804800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738805400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738806000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738806600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738807200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738807800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738808400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738809000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738809600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738810200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738810800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738811400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738812000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738812600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738813200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738813800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738814400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738815000000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738815600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738816200000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738816800000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738817400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738818000000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738818600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738819200000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738819800000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738820400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738821000000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738821600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738822200000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738822800000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738823400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738824000000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738824600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738825200000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738825800000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738826400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738827000000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738827600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738828200000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738828800000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738829400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738830000000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738830600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738831200000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738831800000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738832400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738833000000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738833600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738834200000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738834800000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738835400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738836000000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738836600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738837200000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738837800000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738838400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738839000000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738839600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738840200000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738840800000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738841400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738842000000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738842600000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738843200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738843800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738844400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738845000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738845600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738846200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738846800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738847400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738848000000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738848600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738849200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738849800000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738850400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738851000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738851600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738852200000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738852800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738853400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738854000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738854600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738855200000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738855800000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738856400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738857000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738857600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738858200000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738858800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738859400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738860000000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738860600000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738861200000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738861800000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738862400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738863000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738863600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738864200000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738864800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738865400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738866000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738866600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738867200000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738867800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738868400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738869000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738869600000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738870200000,
                "y": 43.13,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738870800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738871400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738872000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738872600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738873200000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738873800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738874400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738875000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738875600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738876200000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738876800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738877400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738878000000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738878600000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738879200000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738879800000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738880400000,
                "y": 42.5,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738881000000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738881600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738882200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738882800000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738883400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738884000000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738884600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738885200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738885800000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738886400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738887000000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738887600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738888200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738888800000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738889400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738890000000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738890600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738891200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738891800000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738892400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738893000000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738893600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738894200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738894800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738895400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738896000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738896600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738897200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738897800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738898400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738899000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738899600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738900200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738900800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738901400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738902000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738902600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738903200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738903800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738904400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738905000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738905600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738906200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738906800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738907400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738908000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738908600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738909200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738909800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738910400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738911000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738911600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738912200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738912800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738913400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738914000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738914600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738915200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738915800000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738916400000,
                "y": 40.63,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738917000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738917600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738918200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738918800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738919400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738920000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738920600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738921200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738921800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738922400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738923000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738923600000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738924200000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738924800000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738925400000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738926000000,
                "y": 41.25,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738926600000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738927200000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738927800000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            },
            {
                "x": 1738928400000,
                "y": 41.88,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 20 cm",
                "sensorDepth": 20,
                "deviceId": 10045,
                "sensorId": 1,
                "additionalInfo": null
            }
        ],
        "40": [
            {
                "x": 1738537200000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738537800000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738538400000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738539000000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738539600000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738540200000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738540800000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738541400000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738542000000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738542600000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738543200000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738543800000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738544400000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738545000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738545600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738546200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738546800000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738547400000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738548000000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738548600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738549200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738549800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738550400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738551000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738551600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738552200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738552800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738553400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738554000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738554600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738555200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738555800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738556400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738557000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738557600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738558200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738558800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738559400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738560000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738560600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738561200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738561800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738562400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738563000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738563600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738564200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738564800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738565400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738566000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738566600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738567200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738567800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738568400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738569000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738569600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738570200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738570800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738571400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738572000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738572600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738573200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738573800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738574400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738575000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738575600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738576200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738576800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738577400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738578000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738578600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738579200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738579800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738580400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738581000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738581600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738582200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738582800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738583400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738584000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738584600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738585200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738585800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738586400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738587000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738587600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738588200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738588800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738589400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738590000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738590600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738591200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738591800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738592400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738593000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738593600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738594200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738594800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738595400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738596000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738596600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738597200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738597800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738598400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738599000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738599600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738600200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738600800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738601400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738602000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738602600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738603200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738603800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738604400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738605000000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738605600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738606200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738606800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738607400000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738608000000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738608600000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738609200000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738609800000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738610400000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738611000000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738611600000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738612200000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738612800000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738613400000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738614000000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738614600000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738615200000,
                "y": 46.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738615800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738616400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738617000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738617600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738618200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738618800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738619400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738620000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738620600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738621200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738621800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738622400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738623000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738623600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738624200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738624800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738625400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738626000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738626600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738627200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738627800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738628400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738629000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738629600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738630200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738630800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738631400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738632000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738632600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738633200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738633800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738634400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738635000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738635600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738636200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738636800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738637400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738638000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738638600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738639200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738639800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738640400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738641000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738641600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738642200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738642800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738643400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738644000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738644600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738645200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738645800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738646400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738647000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738647600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738648200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738648800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738649400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738650000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738650600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738651200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738651800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738652400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738653000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738653600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738654200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738654800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738655400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738656000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738656600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738657200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738657800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738658400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738659000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738659600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738660200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738660800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738661400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738662000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738662600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738663200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738663800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738664400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738665000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738665600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738666200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738666800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738667400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738668000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738668600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738669200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738669800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738670400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738671000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738671600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738672200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738672800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738673400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738674000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738674600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738675200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738675800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738676400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738677000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738677600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738678200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738678800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738679400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738680000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738680600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738681200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738681800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738682400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738683000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738683600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738684200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738684800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738685400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738686000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738686600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738687200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738687800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738688400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738689000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738689600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738690200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738690800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738691400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738692000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738692600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738693200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738693800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738694400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738695000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738695600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738696200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738696800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738697400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738698000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738698600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738699200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738699800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738700400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738701000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738701600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738702200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738702800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738703400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738704000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738704600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738705200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738705800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738706400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738707000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738707600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738708200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738708800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738709400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738710000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738710600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738711200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738711800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738712400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738713000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738713600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738714200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738714800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738715400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738716000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738716600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738717200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738717800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738718400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738719000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738719600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738720200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738720800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738721400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738722000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738722600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738723200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738723800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738724400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738725000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738725600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738726200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738726800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738727400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738728000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738728600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738729200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738729800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738730400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738731000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738731600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738732200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738732800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738733400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738734000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738734600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738735200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738735800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738736400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738737000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738737600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738738200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738738800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738739400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738740000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738740600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738741200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738741800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738742400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738743000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738743600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738744200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738744800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738745400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738746000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738746600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738747200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738747800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738748400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738749000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738749600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738750200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738750800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738751400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738752000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738752600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738753200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738753800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738754400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738755000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738755600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738756200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738756800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738757400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738758000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738758600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738759200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738759800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738760400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738761000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738761600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738762200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738762800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738763400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738764000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738764600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738765200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738765800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738766400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738767000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738767600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738768200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738768800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738769400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738770000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738770600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738771200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738771800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738772400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738773000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738773600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738774200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738774800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738775400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738776000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738776600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738777200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738777800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738778400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738779000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738779600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738780200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738780800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738781400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738782000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738782600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738783200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738783800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738784400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738785000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738785600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738786200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738786800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738787400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738788000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738788600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738789200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738789800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738790400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738791000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738791600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738792200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738792800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738793400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738794000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738794600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738795200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738795800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738796400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738797000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738797600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738798200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738798800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738799400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738800000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738800600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738801200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738801800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738802400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738803000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738803600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738804200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738804800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738805400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738806000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738806600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738807200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738807800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738808400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738809000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738809600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738810200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738810800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738811400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738812000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738812600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738813200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738813800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738814400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738815000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738815600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738816200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738816800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738817400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738818000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738818600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738819200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738819800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738820400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738821000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738821600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738822200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738822800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738823400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738824000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738824600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738825200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738825800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738826400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738827000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738827600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738828200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738828800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738829400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738830000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738830600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738831200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738831800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738832400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738833000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738833600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738834200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738834800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738835400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738836000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738836600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738837200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738837800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738838400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738839000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738839600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738840200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738840800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738841400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738842000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738842600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738843200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738843800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738844400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738845000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738845600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738846200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738846800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738847400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738848000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738848600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738849200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738849800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738850400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738851000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738851600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738852200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738852800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738853400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738854000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738854600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738855200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738855800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738856400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738857000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738857600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738858200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738858800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738859400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738860000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738860600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738861200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738861800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738862400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738863000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738863600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738864200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738864800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738865400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738866000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738866600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738867200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738867800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738868400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738869000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738869600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738870200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738870800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738871400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738872000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738872600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738873200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738873800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738874400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738875000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738875600000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738876200000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738876800000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738877400000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738878000000,
                "y": 46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738878600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738879200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738879800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738880400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738881000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738881600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738882200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738882800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738883400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738884000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738884600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738885200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738885800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738886400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738887000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738887600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738888200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738888800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738889400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738890000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738890600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738891200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738891800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738892400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738893000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738893600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738894200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738894800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738895400000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738896000000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738896600000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738897200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738897800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738898400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738899000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738899600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738900200000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738900800000,
                "y": 45.33,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738901400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738902000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738902600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738903200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738903800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738904400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738905000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738905600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738906200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738906800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738907400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738908000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738908600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738909200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738909800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738910400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738911000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738911600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738912200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738912800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738913400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738914000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738914600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738915200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738915800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738916400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738917000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738917600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738918200000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738918800000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738919400000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738920000000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738920600000,
                "y": 44,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738921200000,
                "y": 44,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738921800000,
                "y": 44,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738922400000,
                "y": 44,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738923000000,
                "y": 44,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738923600000,
                "y": 44,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738924200000,
                "y": 44,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738924800000,
                "y": 44,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738925400000,
                "y": 44,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738926000000,
                "y": 44,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738926600000,
                "y": 44.67,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738927200000,
                "y": 44,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738927800000,
                "y": 44,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            },
            {
                "x": 1738928400000,
                "y": 44,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 40 cm",
                "sensorDepth": 40,
                "deviceId": 10045,
                "sensorId": 3,
                "additionalInfo": null
            }
        ],
        "60": [
            {
                "x": 1738537200000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738537800000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738538400000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738539000000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738539600000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738540200000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738540800000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738541400000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738542000000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738542600000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738543200000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738543800000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738544400000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738545000000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738545600000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738546200000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738546800000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738547400000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738548000000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738548600000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738549200000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738549800000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738550400000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738551000000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738551600000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738552200000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738552800000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738553400000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738554000000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738554600000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738555200000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738555800000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738556400000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738557000000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738557600000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738558200000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738558800000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738559400000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738560000000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738560600000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738561200000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738561800000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738562400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738563000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738563600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738564200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738564800000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738565400000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738566000000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738566600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738567200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738567800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738568400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738569000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738569600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738570200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738570800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738571400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738572000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738572600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738573200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738573800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738574400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738575000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738575600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738576200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738576800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738577400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738578000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738578600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738579200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738579800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738580400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738581000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738581600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738582200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738582800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738583400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738584000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738584600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738585200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738585800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738586400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738587000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738587600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738588200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738588800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738589400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738590000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738590600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738591200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738591800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738592400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738593000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738593600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738594200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738594800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738595400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738596000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738596600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738597200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738597800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738598400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738599000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738599600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738600200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738600800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738601400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738602000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738602600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738603200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738603800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738604400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738605000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738605600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738606200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738606800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738607400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738608000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738608600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738609200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738609800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738610400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738611000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738611600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738612200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738612800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738613400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738614000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738614600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738615200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738615800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738616400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738617000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738617600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738618200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738618800000,
                "y": 50,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738619400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738620000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738620600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738621200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738621800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738622400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738623000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738623600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738624200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738624800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738625400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738626000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738626600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738627200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738627800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738628400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738629000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738629600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738630200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738630800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738631400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738632000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738632600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738633200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738633800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738634400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738635000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738635600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738636200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738636800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738637400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738638000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738638600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738639200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738639800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738640400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738641000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738641600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738642200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738642800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738643400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738644000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738644600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738645200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738645800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738646400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738647000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738647600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738648200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738648800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738649400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738650000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738650600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738651200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738651800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738652400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738653000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738653600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738654200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738654800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738655400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738656000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738656600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738657200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738657800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738658400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738659000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738659600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738660200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738660800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738661400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738662000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738662600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738663200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738663800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738664400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738665000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738665600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738666200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738666800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738667400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738668000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738668600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738669200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738669800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738670400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738671000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738671600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738672200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738672800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738673400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738674000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738674600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738675200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738675800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738676400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738677000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738677600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738678200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738678800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738679400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738680000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738680600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738681200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738681800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738682400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738683000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738683600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738684200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738684800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738685400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738686000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738686600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738687200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738687800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738688400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738689000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738689600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738690200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738690800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738691400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738692000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738692600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738693200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738693800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738694400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738695000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738695600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738696200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738696800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738697400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738698000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738698600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738699200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738699800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738700400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738701000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738701600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738702200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738702800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738703400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738704000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738704600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738705200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738705800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738706400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738707000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738707600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738708200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738708800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738709400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738710000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738710600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738711200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738711800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738712400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738713000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738713600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738714200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738714800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738715400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738716000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738716600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738717200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738717800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738718400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738719000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738719600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738720200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738720800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738721400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738722000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738722600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738723200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738723800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738724400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738725000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738725600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738726200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738726800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738727400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738728000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738728600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738729200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738729800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738730400000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738731000000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738731600000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738732200000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738732800000,
                "y": 49.23,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738733400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738734000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738734600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738735200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738735800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738736400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738737000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738737600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738738200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738738800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738739400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738740000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738740600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738741200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738741800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738742400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738743000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738743600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738744200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738744800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738745400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738746000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738746600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738747200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738747800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738748400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738749000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738749600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738750200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738750800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738751400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738752000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738752600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738753200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738753800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738754400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738755000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738755600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738756200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738756800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738757400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738758000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738758600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738759200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738759800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738760400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738761000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738761600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738762200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738762800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738763400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738764000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738764600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738765200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738765800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738766400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738767000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738767600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738768200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738768800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738769400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738770000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738770600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738771200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738771800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738772400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738773000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738773600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738774200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738774800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738775400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738776000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738776600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738777200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738777800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738778400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738779000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738779600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738780200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738780800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738781400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738782000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738782600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738783200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738783800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738784400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738785000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738785600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738786200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738786800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738787400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738788000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738788600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738789200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738789800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738790400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738791000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738791600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738792200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738792800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738793400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738794000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738794600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738795200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738795800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738796400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738797000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738797600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738798200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738798800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738799400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738800000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738800600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738801200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738801800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738802400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738803000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738803600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738804200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738804800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738805400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738806000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738806600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738807200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738807800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738808400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738809000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738809600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738810200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738810800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738811400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738812000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738812600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738813200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738813800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738814400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738815000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738815600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738816200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738816800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738817400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738818000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738818600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738819200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738819800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738820400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738821000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738821600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738822200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738822800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738823400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738824000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738824600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738825200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738825800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738826400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738827000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738827600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738828200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738828800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738829400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738830000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738830600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738831200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738831800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738832400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738833000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738833600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738834200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738834800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738835400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738836000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738836600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738837200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738837800000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738838400000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738839000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738839600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738840200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738840800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738841400000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738842000000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738842600000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738843200000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738843800000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738844400000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738845000000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738845600000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738846200000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738846800000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738847400000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738848000000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738848600000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738849200000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738849800000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738850400000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738851000000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738851600000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738852200000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738852800000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738853400000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738854000000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738854600000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738855200000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738855800000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738856400000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738857000000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738857600000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738858200000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738858800000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738859400000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738860000000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738860600000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738861200000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738861800000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738862400000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738863000000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738863600000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738864200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738864800000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738865400000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738866000000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738866600000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738867200000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738867800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738868400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738869000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738869600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738870200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738870800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738871400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738872000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738872600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738873200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738873800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738874400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738875000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738875600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738876200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738876800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738877400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738878000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738878600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738879200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738879800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738880400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738881000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738881600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738882200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738882800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738883400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738884000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738884600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738885200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738885800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738886400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738887000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738887600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738888200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738888800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738889400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738890000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738890600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738891200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738891800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738892400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738893000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738893600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738894200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738894800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738895400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738896000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738896600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738897200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738897800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738898400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738899000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738899600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738900200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738900800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738901400000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738902000000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738902600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738903200000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738903800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738904400000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738905000000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738905600000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738906200000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738906800000,
                "y": 48.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738907400000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738908000000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738908600000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738909200000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738909800000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738910400000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738911000000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738911600000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738912200000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738912800000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738913400000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738914000000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738914600000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738915200000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738915800000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738916400000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738917000000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738917600000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738918200000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738918800000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738919400000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738920000000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738920600000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738921200000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738921800000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738922400000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738923000000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738923600000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738924200000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738924800000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738925400000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738926000000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738926600000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738927200000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738927800000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            },
            {
                "x": 1738928400000,
                "y": 47.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 60 cm",
                "sensorDepth": 60,
                "deviceId": 10045,
                "sensorId": 5,
                "additionalInfo": null
            }
        ],
        "80": [
            {
                "x": 1738537200000,
                "y": 68.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738537800000,
                "y": 68.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738538400000,
                "y": 68.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738539000000,
                "y": 68.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738539600000,
                "y": 68.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738540200000,
                "y": 68.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738540800000,
                "y": 68.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738541400000,
                "y": 68.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738542000000,
                "y": 68.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738542600000,
                "y": 68.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738543200000,
                "y": 68.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738543800000,
                "y": 68.46,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738544400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738545000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738545600000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738546200000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738546800000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738547400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738548000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738548600000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738549200000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738549800000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738550400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738551000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738551600000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738552200000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738552800000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738553400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738554000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738554600000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738555200000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738555800000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738556400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738557000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738557600000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738558200000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738558800000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738559400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738560000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738560600000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738561200000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738561800000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738562400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738563000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738563600000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738564200000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738564800000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738565400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738566000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738566600000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738567200000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738567800000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738568400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738569000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738569600000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738570200000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738570800000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738571400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738572000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738572600000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738573200000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738573800000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738574400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738575000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738575600000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738576200000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738576800000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738577400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738578000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738578600000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738579200000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738579800000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738580400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738581000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738581600000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738582200000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738582800000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738583400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738584000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738584600000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738585200000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738585800000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738586400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738587000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738587600000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738588200000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738588800000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738589400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738590000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738590600000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738591200000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738591800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738592400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738593000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738593600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738594200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738594800000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738595400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738596000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738596600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738597200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738597800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738598400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738599000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738599600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738600200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738600800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738601400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738602000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738602600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738603200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738603800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738604400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738605000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738605600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738606200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738606800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738607400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738608000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738608600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738609200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738609800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738610400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738611000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738611600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738612200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738612800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738613400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738614000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738614600000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738615200000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738615800000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738616400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738617000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738617600000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738618200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738618800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738619400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738620000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738620600000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738621200000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738621800000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738622400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738623000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738623600000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738624200000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738624800000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738625400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738626000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738626600000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738627200000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738627800000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738628400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738629000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738629600000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738630200000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738630800000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738631400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738632000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738632600000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738633200000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738633800000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738634400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738635000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738635600000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738636200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738636800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738637400000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738638000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738638600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738639200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738639800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738640400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738641000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738641600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738642200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738642800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738643400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738644000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738644600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738645200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738645800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738646400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738647000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738647600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738648200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738648800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738649400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738650000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738650600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738651200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738651800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738652400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738653000000,
                "y": 67.69,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738653600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738654200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738654800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738655400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738656000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738656600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738657200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738657800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738658400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738659000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738659600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738660200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738660800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738661400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738662000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738662600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738663200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738663800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738664400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738665000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738665600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738666200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738666800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738667400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738668000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738668600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738669200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738669800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738670400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738671000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738671600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738672200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738672800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738673400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738674000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738674600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738675200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738675800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738676400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738677000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738677600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738678200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738678800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738679400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738680000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738680600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738681200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738681800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738682400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738683000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738683600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738684200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738684800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738685400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738686000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738686600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738687200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738687800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738688400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738689000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738689600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738690200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738690800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738691400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738692000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738692600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738693200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738693800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738694400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738695000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738695600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738696200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738696800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738697400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738698000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738698600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738699200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738699800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738700400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738701000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738701600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738702200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738702800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738703400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738704000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738704600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738705200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738705800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738706400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738707000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738707600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738708200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738708800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738709400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738710000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738710600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738711200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738711800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738712400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738713000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738713600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738714200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738714800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738715400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738716000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738716600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738717200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738717800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738718400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738719000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738719600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738720200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738720800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738721400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738722000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738722600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738723200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738723800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738724400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738725000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738725600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738726200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738726800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738727400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738728000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738728600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738729200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738729800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738730400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738731000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738731600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738732200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738732800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738733400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738734000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738734600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738735200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738735800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738736400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738737000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738737600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738738200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738738800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738739400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738740000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738740600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738741200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738741800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738742400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738743000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738743600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738744200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738744800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738745400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738746000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738746600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738747200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738747800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738748400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738749000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738749600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738750200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738750800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738751400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738752000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738752600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738753200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738753800000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738754400000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738755000000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738755600000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738756200000,
                "y": 66.92,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738756800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738757400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738758000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738758600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738759200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738759800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738760400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738761000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738761600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738762200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738762800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738763400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738764000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738764600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738765200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738765800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738766400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738767000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738767600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738768200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738768800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738769400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738770000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738770600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738771200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738771800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738772400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738773000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738773600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738774200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738774800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738775400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738776000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738776600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738777200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738777800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738778400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738779000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738779600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738780200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738780800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738781400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738782000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738782600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738783200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738783800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738784400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738785000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738785600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738786200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738786800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738787400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738788000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738788600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738789200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738789800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738790400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738791000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738791600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738792200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738792800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738793400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738794000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738794600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738795200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738795800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738796400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738797000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738797600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738798200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738798800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738799400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738800000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738800600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738801200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738801800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738802400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738803000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738803600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738804200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738804800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738805400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738806000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738806600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738807200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738807800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738808400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738809000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738809600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738810200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738810800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738811400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738812000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738812600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738813200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738813800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738814400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738815000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738815600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738816200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738816800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738817400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738818000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738818600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738819200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738819800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738820400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738821000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738821600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738822200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738822800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738823400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738824000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738824600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738825200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738825800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738826400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738827000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738827600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738828200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738828800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738829400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738830000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738830600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738831200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738831800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738832400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738833000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738833600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738834200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738834800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738835400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738836000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738836600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738837200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738837800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738838400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738839000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738839600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738840200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738840800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738841400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738842000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738842600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738843200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738843800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738844400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738845000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738845600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738846200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738846800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738847400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738848000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738848600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738849200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738849800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738850400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738851000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738851600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738852200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738852800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738853400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738854000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738854600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738855200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738855800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738856400000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738857000000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738857600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738858200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738858800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738859400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738860000000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738860600000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738861200000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738861800000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738862400000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738863000000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738863600000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738864200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738864800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738865400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738866000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738866600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738867200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738867800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738868400000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738869000000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738869600000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738870200000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738870800000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738871400000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738872000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738872600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738873200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738873800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738874400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738875000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738875600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738876200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738876800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738877400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738878000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738878600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738879200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738879800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738880400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738881000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738881600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738882200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738882800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738883400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738884000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738884600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738885200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738885800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738886400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738887000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738887600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738888200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738888800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738889400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738890000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738890600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738891200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738891800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738892400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738893000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738893600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738894200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738894800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738895400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738896000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738896600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738897200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738897800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738898400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738899000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738899600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738900200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738900800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738901400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738902000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738902600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738903200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738903800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738904400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738905000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738905600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738906200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738906800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738907400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738908000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738908600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738909200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738909800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738910400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738911000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738911600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738912200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738912800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738913400000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738914000000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738914600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738915200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738915800000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738916400000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738917000000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738917600000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738918200000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738918800000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738919400000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738920000000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738920600000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738921200000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738921800000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738922400000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738923000000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738923600000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738924200000,
                "y": 66.15,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738924800000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738925400000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738926000000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738926600000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738927200000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738927800000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            },
            {
                "x": 1738928400000,
                "y": 65.38,
                "unit": "PERCENT",
                "symbol": "%",
                "name": "VWC 80 cm",
                "sensorDepth": 80,
                "deviceId": 10045,
                "sensorId": 7,
                "additionalInfo": null
            }
        ],
        "IRRIGATION_COUNTER": [],
        "IRRIGATION_PULSES": [],
        "RAIN_GAUGE": [],
        "ACCUMULATED_IRRIGATION": [],
        "ACCUMULATED_IRRIGATION_TIME": []
    }
}

export const MOCK_CAMPAIGN_IRRIGATION = {
    "id": 1,
    "grouping": null,
    "lastIrrigation": null,
    "series": {
        "CAMPAIGN_IRRIGATION": [
            {
                "x": 1735686000000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1738364400000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1740783600000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1743458400000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1746050400000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1748728800000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1751320800000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1753999200000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1756677600000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1759269600000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1761951600000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1764543600000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            }
        ],
        "CAMPAIGN_IRRIGATION_ACCUMULATED": [
            {
                "x": 1735686000000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1738364400000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1740783600000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1743458400000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1746050400000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1748728800000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1751320800000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1753999200000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1756677600000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1759269600000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1761951600000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1764543600000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            }
        ]
    }
}

export const MOCK_NUTRI_DYNAMICS = {
    "id": 2,
    "grouping": "TEN_MINUTES",
    "series": {
      "LINE_ELECTRICAL_CONDUCTIVITY": {
        "0": [
          {
            "x": 1738537200000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738537800000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738538400000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738539000000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738539600000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738540200000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738540800000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738541400000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738542000000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738542600000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738543200000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738543800000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738544400000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738545000000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738545600000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738546200000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738546800000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738547400000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738548000000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738548600000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738549200000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738549800000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738550400000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738551000000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738551600000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738552200000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738552800000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738553400000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738554000000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738554600000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738555200000,
            "y": 1.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738555800000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738556400000,
            "y": 1.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738557000000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738557600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738558200000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738558800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738559400000,
            "y": 0.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738560000000,
            "y": 0.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738560600000,
            "y": 0.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738561200000,
            "y": 0.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738561800000,
            "y": 0.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738562400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738563000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738563600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738564200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738564800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738565400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738566000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738566600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738567200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738567800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738568400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738569000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738569600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738570200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738570800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738571400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738572000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738572600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738573200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738573800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738574400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738575000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738575600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738576200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738576800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738577400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738578000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738578600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738579200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738579800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738580400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738581000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738581600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738582200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738582800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738583400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738584000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738584600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738585200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738585800000,
            "y": 0.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738586400000,
            "y": 0.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738587000000,
            "y": 0.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738587600000,
            "y": 0.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738588200000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738588800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738589400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738590000000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738590600000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738591200000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738591800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738592400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738593000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738593600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738594200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738594800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738595400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738596000000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738596600000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738597200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738597800000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738598400000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738599000000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738599600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738600200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738600800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738601400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738602000000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738602600000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738603200000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738603800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738604400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738605000000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738605600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738606200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738606800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738607400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738608000000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738608600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738609200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738609800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738610400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738611000000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738611600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738612200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738612800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738613400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738614000000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738614600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738615200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738615800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738616400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738617000000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738617600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738618200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738618800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738619400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738620000000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738620600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738621200000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738621800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738622400000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738623000000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738623600000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738624200000,
            "y": 1.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738624800000,
            "y": 1.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738625400000,
            "y": 1.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738626000000,
            "y": 1.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738626600000,
            "y": 1.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738627200000,
            "y": 1.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738627800000,
            "y": 1.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738628400000,
            "y": 1.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738629000000,
            "y": 1.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738629600000,
            "y": 1.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738630200000,
            "y": 1.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738630800000,
            "y": 1.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738631400000,
            "y": 1.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738632000000,
            "y": 1.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738632600000,
            "y": 1.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738633200000,
            "y": 1.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738633800000,
            "y": 1.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738634400000,
            "y": 1.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738635000000,
            "y": 1.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738635600000,
            "y": 1.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738636200000,
            "y": 1.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738636800000,
            "y": 1.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738637400000,
            "y": 1.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738638000000,
            "y": 1.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738638600000,
            "y": 1.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738639200000,
            "y": 1.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738639800000,
            "y": 1.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738640400000,
            "y": 1.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738641000000,
            "y": 1.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738641600000,
            "y": 1.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738642200000,
            "y": 1.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738642800000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738643400000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738644000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738644600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738645200000,
            "y": 0.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738645800000,
            "y": 0.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738646400000,
            "y": 0.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738647000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738647600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738648200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738648800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738649400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738650000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738650600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738651200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738651800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738652400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738653000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738653600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738654200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738654800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738655400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738656000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738656600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738657200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738657800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738658400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738659000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738659600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738660200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738660800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738661400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738662000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738662600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738663200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738663800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738664400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738665000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738665600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738666200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738666800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738667400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738668000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738668600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738669200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738669800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738670400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738671000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738671600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738672200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738672800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738673400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738674000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738674600000,
            "y": 0.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738675200000,
            "y": 0.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738675800000,
            "y": 0.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738676400000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738677000000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738677600000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738678200000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738678800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738679400000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738680000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738680600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738681200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738681800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738682400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738683000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738683600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738684200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738684800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738685400000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738686000000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738686600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738687200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738687800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738688400000,
            "y": 1.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738689000000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738689600000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738690200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738690800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738691400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738692000000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738692600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738693200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738693800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738694400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738695000000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738695600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738696200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738696800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738697400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738698000000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738698600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738699200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738699800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738700400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738701000000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738701600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738702200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738702800000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738703400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738704000000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738704600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738705200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738705800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738706400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738707000000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738707600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738708200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738708800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738709400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738710000000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738710600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738711200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738711800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738712400000,
            "y": 1.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738713000000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738713600000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738714200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738714800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738715400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738716000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738716600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738717200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738717800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738718400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738719000000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738719600000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738720200000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738720800000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738721400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738722000000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738722600000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738723200000,
            "y": 1.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738723800000,
            "y": 1.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738724400000,
            "y": 1.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738725000000,
            "y": 1.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738725600000,
            "y": 1.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738726200000,
            "y": 1.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738726800000,
            "y": 1.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738727400000,
            "y": 1.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738728000000,
            "y": 1.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738728600000,
            "y": 1.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738729200000,
            "y": 1.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738729800000,
            "y": 1.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738730400000,
            "y": 1.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738731000000,
            "y": 1.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738731600000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738732200000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738732800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738733400000,
            "y": 0.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738734000000,
            "y": 0.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738734600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738735200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738735800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738736400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738737000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738737600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738738200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738738800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738739400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738740000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738740600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738741200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738741800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738742400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738743000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738743600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738744200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738744800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738745400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738746000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738746600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738747200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738747800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738748400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738749000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738749600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738750200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738750800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738751400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738752000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738752600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738753200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738753800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738754400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738755000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738755600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738756200000,
            "y": 0.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738756800000,
            "y": 0.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738757400000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738758000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738758600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738759200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738759800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738760400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738761000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738761600000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738762200000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738762800000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738763400000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738764000000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738764600000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738765200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738765800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738766400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738767000000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738767600000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738768200000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738768800000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738769400000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738770000000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738770600000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738771200000,
            "y": 1.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738771800000,
            "y": 1.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738772400000,
            "y": 1.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738773000000,
            "y": 1.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738773600000,
            "y": 1.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738774200000,
            "y": 1.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738774800000,
            "y": 1.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738775400000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738776000000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738776600000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738777200000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738777800000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738778400000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738779000000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738779600000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738780200000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738780800000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738781400000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738782000000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738782600000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738783200000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738783800000,
            "y": 1.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738784400000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738785000000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738785600000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738786200000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738786800000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738787400000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738788000000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738788600000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738789200000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738789800000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738790400000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738791000000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738791600000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738792200000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738792800000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738793400000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738794000000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738794600000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738795200000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738795800000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738796400000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738797000000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738797600000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738798200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738798800000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738799400000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738800000000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738800600000,
            "y": 1.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738801200000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738801800000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738802400000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738803000000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738803600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738804200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738804800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738805400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738806000000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738806600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738807200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738807800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738808400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738809000000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738809600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738810200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738810800000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738811400000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738812000000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738812600000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738813200000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738813800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738814400000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738815000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738815600000,
            "y": 0.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738816200000,
            "y": 0.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738816800000,
            "y": 0.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738817400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738818000000,
            "y": 0.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738818600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738819200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738819800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738820400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738821000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738821600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738822200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738822800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738823400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738824000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738824600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738825200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738825800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738826400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738827000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738827600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738828200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738828800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738829400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738830000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738830600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738831200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738831800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738832400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738833000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738833600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738834200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738834800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738835400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738836000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738836600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738837200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738837800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738838400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738839000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738839600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738840200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738840800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738841400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738842000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738842600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738843200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738843800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738844400000,
            "y": 0.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738845000000,
            "y": 0.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738845600000,
            "y": 0.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738846200000,
            "y": 0.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738846800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738847400000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738848000000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738848600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738849200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738849800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738850400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738851000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738851600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738852200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738852800000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738853400000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738854000000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738854600000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738855200000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738855800000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738856400000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738857000000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738857600000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738858200000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738858800000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738859400000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738860000000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738860600000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738861200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738861800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738862400000,
            "y": 1.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738863000000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738863600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738864200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738864800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738865400000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738866000000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738866600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738867200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738867800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738868400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738869000000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738869600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738870200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738870800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738871400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738872000000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738872600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738873200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738873800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738874400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738875000000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738875600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738876200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738876800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738877400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738878000000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738878600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738879200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738879800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738880400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738881000000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738881600000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738882200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738882800000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738883400000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738884000000,
            "y": 1.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738884600000,
            "y": 1.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738885200000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738885800000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738886400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738887000000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738887600000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738888200000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738888800000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738889400000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738890000000,
            "y": 1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738890600000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738891200000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738891800000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738892400000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738893000000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738893600000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738894200000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738894800000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738895400000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738896000000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738896600000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738897200000,
            "y": 1.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738897800000,
            "y": 1.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738898400000,
            "y": 1.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738899000000,
            "y": 1.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738899600000,
            "y": 1.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738900200000,
            "y": 1.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738900800000,
            "y": 1.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738901400000,
            "y": 2.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738902000000,
            "y": 6.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738902600000,
            "y": 17.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738903200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738903800000,
            "y": 0.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738904400000,
            "y": 0.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738905000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738905600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738906200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738906800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738907400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738908000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738908600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738909200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738909800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738910400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738911000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738911600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738912200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738912800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738913400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738914000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738914600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738915200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738915800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738916400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738917000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738917600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738918200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738918800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738919400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738920000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738920600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738921200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738921800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738922400000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738923000000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738923600000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738924200000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738924800000,
            "y": 0,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738925400000,
            "y": 1.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738926000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738926600000,
            "y": 0.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738927200000,
            "y": 0.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738927800000,
            "y": 0.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          }
        ]
      },
      "TEMPERATURE": {
        "20": [
          {
            "x": 1738537200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738537800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738538400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738539000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738539600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738540200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738540800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738541400000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738542000000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738542600000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738543200000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738543800000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738544400000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738545000000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738545600000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738546200000,
            "y": 5.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738546800000,
            "y": 5.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738547400000,
            "y": 5.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738548000000,
            "y": 5.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738548600000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738549200000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738549800000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738550400000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738551000000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738551600000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738552200000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738552800000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738553400000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738554000000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738554600000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738555200000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738555800000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738556400000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738557000000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738557600000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738558200000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738558800000,
            "y": 5.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738559400000,
            "y": 5.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738560000000,
            "y": 5.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738560600000,
            "y": 5.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738561200000,
            "y": 5.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738561800000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738562400000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738563000000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738563600000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738564200000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738564800000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738565400000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738566000000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738566600000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738567200000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738567800000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738568400000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738569000000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738569600000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738570200000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738570800000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738571400000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738572000000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738572600000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738573200000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738573800000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738574400000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738575000000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738575600000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738576200000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738576800000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738577400000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738578000000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738578600000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738579200000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738579800000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738580400000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738581000000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738581600000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738582200000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738582800000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738583400000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738584000000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738584600000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738585200000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738585800000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738586400000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738587000000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738587600000,
            "y": 5.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738588200000,
            "y": 5.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738588800000,
            "y": 5.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738589400000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738590000000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738590600000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738591200000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738591800000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738592400000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738593000000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738593600000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738594200000,
            "y": 5.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738594800000,
            "y": 5.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738595400000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738596000000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738596600000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738597200000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738597800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738598400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738599000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738599600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738600200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738600800000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738601400000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738602000000,
            "y": 6.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738602600000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738603200000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738603800000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738604400000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738605000000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738605600000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738606200000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738606800000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738607400000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738608000000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738608600000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738609200000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738609800000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738610400000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738611000000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738611600000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738612200000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738612800000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738613400000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738614000000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738614600000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738615200000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738615800000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738616400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738617000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738617600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738618200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738618800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738619400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738620000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738620600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738621200000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738621800000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738622400000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738623000000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738623600000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738624200000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738624800000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738625400000,
            "y": 5.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738626000000,
            "y": 5.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738626600000,
            "y": 5.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738627200000,
            "y": 5.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738627800000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738628400000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738629000000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738629600000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738630200000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738630800000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738631400000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738632000000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738632600000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738633200000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738633800000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738634400000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738635000000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738635600000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738636200000,
            "y": 5.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738636800000,
            "y": 5.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738637400000,
            "y": 5.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738638000000,
            "y": 5.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738638600000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738639200000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738639800000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738640400000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738641000000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738641600000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738642200000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738642800000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738643400000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738644000000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738644600000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738645200000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738645800000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738646400000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738647000000,
            "y": 4.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738647600000,
            "y": 4.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738648200000,
            "y": 4.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738648800000,
            "y": 4.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738649400000,
            "y": 4.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738650000000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738650600000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738651200000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738651800000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738652400000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738653000000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738653600000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738654200000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738654800000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738655400000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738656000000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738656600000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738657200000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738657800000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738658400000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738659000000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738659600000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738660200000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738660800000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738661400000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738662000000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738662600000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738663200000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738663800000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738664400000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738665000000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738665600000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738666200000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738666800000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738667400000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738668000000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738668600000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738669200000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738669800000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738670400000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738671000000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738671600000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738672200000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738672800000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738673400000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738674000000,
            "y": 4.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738674600000,
            "y": 4.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738675200000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738675800000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738676400000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738677000000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738677600000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738678200000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738678800000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738679400000,
            "y": 5.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738680000000,
            "y": 5.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738680600000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738681200000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738681800000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738682400000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738683000000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738683600000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738684200000,
            "y": 5.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738684800000,
            "y": 5.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738685400000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738686000000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738686600000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738687200000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738687800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738688400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738689000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738689600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738690200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738690800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738691400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738692000000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738692600000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738693200000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738693800000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738694400000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738695000000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738695600000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738696200000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738696800000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738697400000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738698000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738698600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738699200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738699800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738700400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738701000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738701600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738702200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738702800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738703400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738704000000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738704600000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738705200000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738705800000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738706400000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738707000000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738707600000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738708200000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738708800000,
            "y": 5.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738709400000,
            "y": 5.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738710000000,
            "y": 5.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738710600000,
            "y": 5.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738711200000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738711800000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738712400000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738713000000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738713600000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738714200000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738714800000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738715400000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738716000000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738716600000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738717200000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738717800000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738718400000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738719000000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738719600000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738720200000,
            "y": 5.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738720800000,
            "y": 5.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738721400000,
            "y": 5.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738722000000,
            "y": 5.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738722600000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738723200000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738723800000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738724400000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738725000000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738725600000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738726200000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738726800000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738727400000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738728000000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738728600000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738729200000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738729800000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738730400000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738731000000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738731600000,
            "y": 4.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738732200000,
            "y": 4.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738732800000,
            "y": 4.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738733400000,
            "y": 4.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738734000000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738734600000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738735200000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738735800000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738736400000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738737000000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738737600000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738738200000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738738800000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738739400000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738740000000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738740600000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738741200000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738741800000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738742400000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738743000000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738743600000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738744200000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738744800000,
            "y": 4.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738745400000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738746000000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738746600000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738747200000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738747800000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738748400000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738749000000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738749600000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738750200000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738750800000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738751400000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738752000000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738752600000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738753200000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738753800000,
            "y": 4.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738754400000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738755000000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738755600000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738756200000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738756800000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738757400000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738758000000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738758600000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738759200000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738759800000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738760400000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738761000000,
            "y": 4.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738761600000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738762200000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738762800000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738763400000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738764000000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738764600000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738765200000,
            "y": 5.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738765800000,
            "y": 5.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738766400000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738767000000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738767600000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738768200000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738768800000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738769400000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738770000000,
            "y": 5.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738770600000,
            "y": 5.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738771200000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738771800000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738772400000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738773000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738773600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738774200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738774800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738775400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738776000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738776600000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738777200000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738777800000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738778400000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738779000000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738779600000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738780200000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738780800000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738781400000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738782000000,
            "y": 6.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738782600000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738783200000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738783800000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738784400000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738785000000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738785600000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738786200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738786800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738787400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738788000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738788600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738789200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738789800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738790400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738791000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738791600000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738792200000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738792800000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738793400000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738794000000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738794600000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738795200000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738795800000,
            "y": 5.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738796400000,
            "y": 5.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738797000000,
            "y": 5.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738797600000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738798200000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738798800000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738799400000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738800000000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738800600000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738801200000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738801800000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738802400000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738803000000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738803600000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738804200000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738804800000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738805400000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738806000000,
            "y": 5.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738806600000,
            "y": 5.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738807200000,
            "y": 5.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738807800000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738808400000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738809000000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738809600000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738810200000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738810800000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738811400000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738812000000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738812600000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738813200000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738813800000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738814400000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738815000000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738815600000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738816200000,
            "y": 4.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738816800000,
            "y": 4.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738817400000,
            "y": 4.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738818000000,
            "y": 4.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738818600000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738819200000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738819800000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738820400000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738821000000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738821600000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738822200000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738822800000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738823400000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738824000000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738824600000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738825200000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738825800000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738826400000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738827000000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738827600000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738828200000,
            "y": 4.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738828800000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738829400000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738830000000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738830600000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738831200000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738831800000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738832400000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738833000000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738833600000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738834200000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738834800000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738835400000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738836000000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738836600000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738837200000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738837800000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738838400000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738839000000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738839600000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738840200000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738840800000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738841400000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738842000000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738842600000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738843200000,
            "y": 4.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738843800000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738844400000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738845000000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738845600000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738846200000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738846800000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738847400000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738848000000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738848600000,
            "y": 4.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738849200000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738849800000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738850400000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738851000000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738851600000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738852200000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738852800000,
            "y": 5.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738853400000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738854000000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738854600000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738855200000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738855800000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738856400000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738857000000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738857600000,
            "y": 5.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738858200000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738858800000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738859400000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738860000000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738860600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738861200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738861800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738862400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738863000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738863600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738864200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738864800000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738865400000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738866000000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738866600000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738867200000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738867800000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738868400000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738869000000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738869600000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738870200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738870800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738871400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738872000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738872600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738873200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738873800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738874400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738875000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738875600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738876200000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738876800000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738877400000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738878000000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738878600000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738879200000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738879800000,
            "y": 5.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738880400000,
            "y": 5.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738881000000,
            "y": 5.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738881600000,
            "y": 5.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738882200000,
            "y": 5.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738882800000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738883400000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738884000000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738884600000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738885200000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738885800000,
            "y": 5.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738886400000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738887000000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738887600000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738888200000,
            "y": 5.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738888800000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738889400000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738890000000,
            "y": 5.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738890600000,
            "y": 5.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738891200000,
            "y": 5.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738891800000,
            "y": 5.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738892400000,
            "y": 5.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738893000000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738893600000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738894200000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738894800000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738895400000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738896000000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738896600000,
            "y": 5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738897200000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738897800000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738898400000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738899000000,
            "y": 4.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738899600000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738900200000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738900800000,
            "y": 4.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738901400000,
            "y": 4.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738902000000,
            "y": 4.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738902600000,
            "y": 4.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738903200000,
            "y": 4.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738903800000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738904400000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738905000000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738905600000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738906200000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738906800000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738907400000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738908000000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738908600000,
            "y": 4.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738909200000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738909800000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738910400000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738911000000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738911600000,
            "y": 4.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738912200000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738912800000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738913400000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738914000000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738914600000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738915200000,
            "y": 4.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738915800000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738916400000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738917000000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738917600000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738918200000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738918800000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738919400000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738920000000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738920600000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738921200000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738921800000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738922400000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738923000000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738923600000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738924200000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738924800000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738925400000,
            "y": 4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738926000000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738926600000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738927200000,
            "y": 4.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738927800000,
            "y": 4.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          }
        ],
        "40": [
          {
            "x": 1738537200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738537800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738538400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738539000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738539600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738540200000,
            "y": 7.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738540800000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738541400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738542000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738542600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738543200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738543800000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738544400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738545000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738545600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738546200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738546800000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738547400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738548000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738548600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738549200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738549800000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738550400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738551000000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738551600000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738552200000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738552800000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738553400000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738554000000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738554600000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738555200000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738555800000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738556400000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738557000000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738557600000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738558200000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738558800000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738559400000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738560000000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738560600000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738561200000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738561800000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738562400000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738563000000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738563600000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738564200000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738564800000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738565400000,
            "y": 6.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738566000000,
            "y": 6.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738566600000,
            "y": 6.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738567200000,
            "y": 6.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738567800000,
            "y": 6.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738568400000,
            "y": 6.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738569000000,
            "y": 6.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738569600000,
            "y": 6.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738570200000,
            "y": 6.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738570800000,
            "y": 6.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738571400000,
            "y": 6.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738572000000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738572600000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738573200000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738573800000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738574400000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738575000000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738575600000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738576200000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738576800000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738577400000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738578000000,
            "y": 6.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738578600000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738579200000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738579800000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738580400000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738581000000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738581600000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738582200000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738582800000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738583400000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738584000000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738584600000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738585200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738585800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738586400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738587000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738587600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738588200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738588800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738589400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738590000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738590600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738591200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738591800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738592400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738593000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738593600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738594200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738594800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738595400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738596000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738596600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738597200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738597800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738598400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738599000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738599600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738600200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738600800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738601400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738602000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738602600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738603200000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738603800000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738604400000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738605000000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738605600000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738606200000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738606800000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738607400000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738608000000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738608600000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738609200000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738609800000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738610400000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738611000000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738611600000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738612200000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738612800000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738613400000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738614000000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738614600000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738615200000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738615800000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738616400000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738617000000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738617600000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738618200000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738618800000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738619400000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738620000000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738620600000,
            "y": 6.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738621200000,
            "y": 6.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738621800000,
            "y": 6.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738622400000,
            "y": 6.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738623000000,
            "y": 6.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738623600000,
            "y": 6.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738624200000,
            "y": 6.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738624800000,
            "y": 6.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738625400000,
            "y": 6.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738626000000,
            "y": 6.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738626600000,
            "y": 6.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738627200000,
            "y": 6.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738627800000,
            "y": 6.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738628400000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738629000000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738629600000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738630200000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738630800000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738631400000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738632000000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738632600000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738633200000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738633800000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738634400000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738635000000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738635600000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738636200000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738636800000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738637400000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738638000000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738638600000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738639200000,
            "y": 6.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738639800000,
            "y": 6.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738640400000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738641000000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738641600000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738642200000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738642800000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738643400000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738644000000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738644600000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738645200000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738645800000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738646400000,
            "y": 6.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738647000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738647600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738648200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738648800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738649400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738650000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738650600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738651200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738651800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738652400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738653000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738653600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738654200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738654800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738655400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738656000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738656600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738657200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738657800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738658400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738659000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738659600000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738660200000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738660800000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738661400000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738662000000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738662600000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738663200000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738663800000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738664400000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738665000000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738665600000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738666200000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738666800000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738667400000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738668000000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738668600000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738669200000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738669800000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738670400000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738671000000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738671600000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738672200000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738672800000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738673400000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738674000000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738674600000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738675200000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738675800000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738676400000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738677000000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738677600000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738678200000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738678800000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738679400000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738680000000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738680600000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738681200000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738681800000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738682400000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738683000000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738683600000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738684200000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738684800000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738685400000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738686000000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738686600000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738687200000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738687800000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738688400000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738689000000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738689600000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738690200000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738690800000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738691400000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738692000000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738692600000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738693200000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738693800000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738694400000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738695000000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738695600000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738696200000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738696800000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738697400000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738698000000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738698600000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738699200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738699800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738700400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738701000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738701600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738702200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738702800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738703400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738704000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738704600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738705200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738705800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738706400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738707000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738707600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738708200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738708800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738709400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738710000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738710600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738711200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738711800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738712400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738713000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738713600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738714200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738714800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738715400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738716000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738716600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738717200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738717800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738718400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738719000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738719600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738720200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738720800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738721400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738722000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738722600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738723200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738723800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738724400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738725000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738725600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738726200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738726800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738727400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738728000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738728600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738729200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738729800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738730400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738731000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738731600000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738732200000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738732800000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738733400000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738734000000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738734600000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738735200000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738735800000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738736400000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738737000000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738737600000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738738200000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738738800000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738739400000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738740000000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738740600000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738741200000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738741800000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738742400000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738743000000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738743600000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738744200000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738744800000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738745400000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738746000000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738746600000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738747200000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738747800000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738748400000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738749000000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738749600000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738750200000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738750800000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738751400000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738752000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738752600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738753200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738753800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738754400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738755000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738755600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738756200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738756800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738757400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738758000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738758600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738759200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738759800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738760400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738761000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738761600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738762200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738762800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738763400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738764000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738764600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738765200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738765800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738766400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738767000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738767600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738768200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738768800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738769400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738770000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738770600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738771200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738771800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738772400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738773000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738773600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738774200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738774800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738775400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738776000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738776600000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738777200000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738777800000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738778400000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738779000000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738779600000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738780200000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738780800000,
            "y": 6.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738781400000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738782000000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738782600000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738783200000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738783800000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738784400000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738785000000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738785600000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738786200000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738786800000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738787400000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738788000000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738788600000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738789200000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738789800000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738790400000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738791000000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738791600000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738792200000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738792800000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738793400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738794000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738794600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738795200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738795800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738796400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738797000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738797600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738798200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738798800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738799400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738800000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738800600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738801200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738801800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738802400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738803000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738803600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738804200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738804800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738805400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738806000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738806600000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738807200000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738807800000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738808400000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738809000000,
            "y": 6.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738809600000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738810200000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738810800000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738811400000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738812000000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738812600000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738813200000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738813800000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738814400000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738815000000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738815600000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738816200000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738816800000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738817400000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738818000000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738818600000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738819200000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738819800000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738820400000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738821000000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738821600000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738822200000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738822800000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738823400000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738824000000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738824600000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738825200000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738825800000,
            "y": 6.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738826400000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738827000000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738827600000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738828200000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738828800000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738829400000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738830000000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738830600000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738831200000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738831800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738832400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738833000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738833600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738834200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738834800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738835400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738836000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738836600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738837200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738837800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738838400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738839000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738839600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738840200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738840800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738841400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738842000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738842600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738843200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738843800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738844400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738845000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738845600000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738846200000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738846800000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738847400000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738848000000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738848600000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738849200000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738849800000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738850400000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738851000000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738851600000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738852200000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738852800000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738853400000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738854000000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738854600000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738855200000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738855800000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738856400000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738857000000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738857600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738858200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738858800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738859400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738860000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738860600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738861200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738861800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738862400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738863000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738863600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738864200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738864800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738865400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738866000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738866600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738867200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738867800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738868400000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738869000000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738869600000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738870200000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738870800000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738871400000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738872000000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738872600000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738873200000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738873800000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738874400000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738875000000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738875600000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738876200000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738876800000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738877400000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738878000000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738878600000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738879200000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738879800000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738880400000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738881000000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738881600000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738882200000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738882800000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738883400000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738884000000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738884600000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738885200000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738885800000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738886400000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738887000000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738887600000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738888200000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738888800000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738889400000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738890000000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738890600000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738891200000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738891800000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738892400000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738893000000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738893600000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738894200000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738894800000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738895400000,
            "y": 6.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738896000000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738896600000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738897200000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738897800000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738898400000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738899000000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738899600000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738900200000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738900800000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738901400000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738902000000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738902600000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738903200000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738903800000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738904400000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738905000000,
            "y": 6.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738905600000,
            "y": 6.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738906200000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738906800000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738907400000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738908000000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738908600000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738909200000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738909800000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738910400000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738911000000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738911600000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738912200000,
            "y": 6.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738912800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738913400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738914000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738914600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738915200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738915800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738916400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738917000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738917600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738918200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738918800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738919400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738920000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738920600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738921200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738921800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738922400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738923000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738923600000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738924200000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738924800000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738925400000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738926000000,
            "y": 6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738926600000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738927200000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738927800000,
            "y": 5.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          }
        ],
        "60": [
          {
            "x": 1738537200000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738537800000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738538400000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738539000000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738539600000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738540200000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738540800000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738541400000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738542000000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738542600000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738543200000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738543800000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738544400000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738545000000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738545600000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738546200000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738546800000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738547400000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738548000000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738548600000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738549200000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738549800000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738550400000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738551000000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738551600000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738552200000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738552800000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738553400000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738554000000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738554600000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738555200000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738555800000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738556400000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738557000000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738557600000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738558200000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738558800000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738559400000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738560000000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738560600000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738561200000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738561800000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738562400000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738563000000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738563600000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738564200000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738564800000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738565400000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738566000000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738566600000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738567200000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738567800000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738568400000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738569000000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738569600000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738570200000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738570800000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738571400000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738572000000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738572600000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738573200000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738573800000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738574400000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738575000000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738575600000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738576200000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738576800000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738577400000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738578000000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738578600000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738579200000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738579800000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738580400000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738581000000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738581600000,
            "y": 8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738582200000,
            "y": 7.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738582800000,
            "y": 7.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738583400000,
            "y": 7.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738584000000,
            "y": 7.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738584600000,
            "y": 7.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738585200000,
            "y": 7.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738585800000,
            "y": 7.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738586400000,
            "y": 7.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738587000000,
            "y": 7.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738587600000,
            "y": 7.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738588200000,
            "y": 7.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738588800000,
            "y": 7.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738589400000,
            "y": 7.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738590000000,
            "y": 7.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738590600000,
            "y": 7.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738591200000,
            "y": 7.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738591800000,
            "y": 7.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738592400000,
            "y": 7.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738593000000,
            "y": 7.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738593600000,
            "y": 7.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738594200000,
            "y": 7.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738594800000,
            "y": 7.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738595400000,
            "y": 7.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738596000000,
            "y": 7.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738596600000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738597200000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738597800000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738598400000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738599000000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738599600000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738600200000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738600800000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738601400000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738602000000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738602600000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738603200000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738603800000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738604400000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738605000000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738605600000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738606200000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738606800000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738607400000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738608000000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738608600000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738609200000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738609800000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738610400000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738611000000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738611600000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738612200000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738612800000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738613400000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738614000000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738614600000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738615200000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738615800000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738616400000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738617000000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738617600000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738618200000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738618800000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738619400000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738620000000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738620600000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738621200000,
            "y": 7.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738621800000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738622400000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738623000000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738623600000,
            "y": 7.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738624200000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738624800000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738625400000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738626000000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738626600000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738627200000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738627800000,
            "y": 7.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738628400000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738629000000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738629600000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738630200000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738630800000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738631400000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738632000000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738632600000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738633200000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738633800000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738634400000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738635000000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738635600000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738636200000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738636800000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738637400000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738638000000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738638600000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738639200000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738639800000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738640400000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738641000000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738641600000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738642200000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738642800000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738643400000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738644000000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738644600000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738645200000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738645800000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738646400000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738647000000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738647600000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738648200000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738648800000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738649400000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738650000000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738650600000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738651200000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738651800000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738652400000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738653000000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738653600000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738654200000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738654800000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738655400000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738656000000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738656600000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738657200000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738657800000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738658400000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738659000000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738659600000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738660200000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738660800000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738661400000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738662000000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738662600000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738663200000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738663800000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738664400000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738665000000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738665600000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738666200000,
            "y": 7.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738666800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738667400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738668000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738668600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738669200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738669800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738670400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738671000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738671600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738672200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738672800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738673400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738674000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738674600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738675200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738675800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738676400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738677000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738677600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738678200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738678800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738679400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738680000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738680600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738681200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738681800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738682400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738683000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738683600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738684200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738684800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738685400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738686000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738686600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738687200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738687800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738688400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738689000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738689600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738690200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738690800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738691400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738692000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738692600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738693200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738693800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738694400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738695000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738695600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738696200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738696800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738697400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738698000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738698600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738699200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738699800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738700400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738701000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738701600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738702200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738702800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738703400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738704000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738704600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738705200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738705800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738706400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738707000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738707600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738708200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738708800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738709400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738710000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738710600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738711200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738711800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738712400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738713000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738713600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738714200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738714800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738715400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738716000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738716600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738717200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738717800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738718400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738719000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738719600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738720200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738720800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738721400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738722000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738722600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738723200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738723800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738724400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738725000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738725600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738726200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738726800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738727400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738728000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738728600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738729200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738729800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738730400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738731000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738731600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738732200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738732800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738733400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738734000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738734600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738735200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738735800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738736400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738737000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738737600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738738200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738738800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738739400000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738740000000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738740600000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738741200000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738741800000,
            "y": 7.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738742400000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738743000000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738743600000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738744200000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738744800000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738745400000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738746000000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738746600000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738747200000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738747800000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738748400000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738749000000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738749600000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738750200000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738750800000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738751400000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738752000000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738752600000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738753200000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738753800000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738754400000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738755000000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738755600000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738756200000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738756800000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738757400000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738758000000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738758600000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738759200000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738759800000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738760400000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738761000000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738761600000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738762200000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738762800000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738763400000,
            "y": 7.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738764000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738764600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738765200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738765800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738766400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738767000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738767600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738768200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738768800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738769400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738770000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738770600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738771200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738771800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738772400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738773000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738773600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738774200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738774800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738775400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738776000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738776600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738777200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738777800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738778400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738779000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738779600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738780200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738780800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738781400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738782000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738782600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738783200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738783800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738784400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738785000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738785600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738786200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738786800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738787400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738788000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738788600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738789200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738789800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738790400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738791000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738791600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738792200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738792800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738793400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738794000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738794600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738795200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738795800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738796400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738797000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738797600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738798200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738798800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738799400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738800000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738800600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738801200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738801800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738802400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738803000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738803600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738804200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738804800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738805400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738806000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738806600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738807200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738807800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738808400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738809000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738809600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738810200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738810800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738811400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738812000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738812600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738813200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738813800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738814400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738815000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738815600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738816200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738816800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738817400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738818000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738818600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738819200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738819800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738820400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738821000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738821600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738822200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738822800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738823400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738824000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738824600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738825200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738825800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738826400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738827000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738827600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738828200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738828800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738829400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738830000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738830600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738831200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738831800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738832400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738833000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738833600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738834200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738834800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738835400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738836000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738836600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738837200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738837800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738838400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738839000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738839600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738840200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738840800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738841400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738842000000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738842600000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738843200000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738843800000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738844400000,
            "y": 7.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738845000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738845600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738846200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738846800000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738847400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738848000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738848600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738849200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738849800000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738850400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738851000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738851600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738852200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738852800000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738853400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738854000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738854600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738855200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738855800000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738856400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738857000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738857600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738858200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738858800000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738859400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738860000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738860600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738861200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738861800000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738862400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738863000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738863600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738864200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738864800000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738865400000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738866000000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738866600000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738867200000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738867800000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738868400000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738869000000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738869600000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738870200000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738870800000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738871400000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738872000000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738872600000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738873200000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738873800000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738874400000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738875000000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738875600000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738876200000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738876800000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738877400000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738878000000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738878600000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738879200000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738879800000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738880400000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738881000000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738881600000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738882200000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738882800000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738883400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738884000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738884600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738885200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738885800000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738886400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738887000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738887600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738888200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738888800000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738889400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738890000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738890600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738891200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738891800000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738892400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738893000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738893600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738894200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738894800000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738895400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738896000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738896600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738897200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738897800000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738898400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738899000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738899600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738900200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738900800000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738901400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738902000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738902600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738903200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738903800000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738904400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738905000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738905600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738906200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738906800000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738907400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738908000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738908600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738909200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738909800000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738910400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738911000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738911600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738912200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738912800000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738913400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738914000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738914600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738915200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738915800000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738916400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738917000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738917600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738918200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738918800000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738919400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738920000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738920600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738921200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738921800000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738922400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738923000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738923600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738924200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738924800000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738925400000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738926000000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738926600000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738927200000,
            "y": 7.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738927800000,
            "y": 7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          }
        ]
      },
      "IRRIGATION_COUNTER": {},
      "RAIN_GAUGE": {},
      "SOIL_ELECTRICAL_CONDUCTIVITY": {
        "20": [
          {
            "x": 1738537200000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738537800000,
            "y": 2.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738538400000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738539000000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738539600000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738540200000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738540800000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738541400000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738542000000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738542600000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738543200000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738543800000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738544400000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738545000000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738545600000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738546200000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738546800000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738547400000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738548000000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738548600000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738549200000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738549800000,
            "y": 2.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738550400000,
            "y": 2.05,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738551000000,
            "y": 2.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738551600000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738552200000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738552800000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738553400000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738554000000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738554600000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738555200000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738555800000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738556400000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738557000000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738557600000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738558200000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738558800000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738559400000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738560000000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738560600000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738561200000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738561800000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738562400000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738563000000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738563600000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738564200000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738564800000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738565400000,
            "y": 2.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738566000000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738566600000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738567200000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738567800000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738568400000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738569000000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738569600000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738570200000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738570800000,
            "y": 2.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738571400000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738572000000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738572600000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738573200000,
            "y": 2.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738573800000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738574400000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738575000000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738575600000,
            "y": 2.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738576200000,
            "y": 2.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738576800000,
            "y": 2.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738577400000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738578000000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738578600000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738579200000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738579800000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738580400000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738581000000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738581600000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738582200000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738582800000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738583400000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738584000000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738584600000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738585200000,
            "y": 2.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738585800000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738586400000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738587000000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738587600000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738588200000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738588800000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738589400000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738590000000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738590600000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738591200000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738591800000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738592400000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738593000000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738593600000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738594200000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738594800000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738595400000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738596000000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738596600000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738597200000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738597800000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738598400000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738599000000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738599600000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738600200000,
            "y": 2.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738600800000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738601400000,
            "y": 2.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738602000000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738602600000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738603200000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738603800000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738604400000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738605000000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738605600000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738606200000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738606800000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738607400000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738608000000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738608600000,
            "y": 2.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738609200000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738609800000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738610400000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738611000000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738611600000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738612200000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738612800000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738613400000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738614000000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738614600000,
            "y": 2.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738615200000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738615800000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738616400000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738617000000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738617600000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738618200000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738618800000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738619400000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738620000000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738620600000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738621200000,
            "y": 2.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738621800000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738622400000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738623000000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738623600000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738624200000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738624800000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738625400000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738626000000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738626600000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738627200000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738627800000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738628400000,
            "y": 2.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738629000000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738629600000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738630200000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738630800000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738631400000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738632000000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738632600000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738633200000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738633800000,
            "y": 2.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738634400000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738635000000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738635600000,
            "y": 2.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738636200000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738636800000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738637400000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738638000000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738638600000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738639200000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738639800000,
            "y": 2.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738640400000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738641000000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738641600000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738642200000,
            "y": 2.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738642800000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738643400000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738644000000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738644600000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738645200000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738645800000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738646400000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738647000000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738647600000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738648200000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738648800000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738649400000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738650000000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738650600000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738651200000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738651800000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738652400000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738653000000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738653600000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738654200000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738654800000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738655400000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738656000000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738656600000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738657200000,
            "y": 2.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738657800000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738658400000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738659000000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738659600000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738660200000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738660800000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738661400000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738662000000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738662600000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738663200000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738663800000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738664400000,
            "y": 2.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738665000000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738665600000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738666200000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738666800000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738667400000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738668000000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738668600000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738669200000,
            "y": 2.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738669800000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738670400000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738671000000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738671600000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738672200000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738672800000,
            "y": 2.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738673400000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738674000000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738674600000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738675200000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738675800000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738676400000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738677000000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738677600000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738678200000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738678800000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738679400000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738680000000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738680600000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738681200000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738681800000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738682400000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738683000000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738683600000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738684200000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738684800000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738685400000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738686000000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738686600000,
            "y": 2.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738687200000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738687800000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738688400000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738689000000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738689600000,
            "y": 2.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738690200000,
            "y": 2.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738690800000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738691400000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738692000000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738692600000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738693200000,
            "y": 2.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738693800000,
            "y": 2.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738694400000,
            "y": 2.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738695000000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738695600000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738696200000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738696800000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738697400000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738698000000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738698600000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738699200000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738699800000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738700400000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738701000000,
            "y": 2.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738701600000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738702200000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738702800000,
            "y": 2.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738703400000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738704000000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738704600000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738705200000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738705800000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738706400000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738707000000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738707600000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738708200000,
            "y": 2.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738708800000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738709400000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738710000000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738710600000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738711200000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738711800000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738712400000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738713000000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738713600000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738714200000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738714800000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738715400000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738716000000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738716600000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738717200000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738717800000,
            "y": 2.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738718400000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738719000000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738719600000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738720200000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738720800000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738721400000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738722000000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738722600000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738723200000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738723800000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738724400000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738725000000,
            "y": 2.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738725600000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738726200000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738726800000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738727400000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738728000000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738728600000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738729200000,
            "y": 2.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738729800000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738730400000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738731000000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738731600000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738732200000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738732800000,
            "y": 2.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738733400000,
            "y": 2.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738734000000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738734600000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738735200000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738735800000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738736400000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738737000000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738737600000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738738200000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738738800000,
            "y": 2.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738739400000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738740000000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738740600000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738741200000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738741800000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738742400000,
            "y": 2.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738743000000,
            "y": 2.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738743600000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738744200000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738744800000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738745400000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738746000000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738746600000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738747200000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738747800000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738748400000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738749000000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738749600000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738750200000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738750800000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738751400000,
            "y": 2.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738752000000,
            "y": 2.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738752600000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738753200000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738753800000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738754400000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738755000000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738755600000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738756200000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738756800000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738757400000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738758000000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738758600000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738759200000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738759800000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738760400000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738761000000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738761600000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738762200000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738762800000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738763400000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738764000000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738764600000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738765200000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738765800000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738766400000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738767000000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738767600000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738768200000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738768800000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738769400000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738770000000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738770600000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738771200000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738771800000,
            "y": 2.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738772400000,
            "y": 2.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738773000000,
            "y": 2.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738773600000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738774200000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738774800000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738775400000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738776000000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738776600000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738777200000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738777800000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738778400000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738779000000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738779600000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738780200000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738780800000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738781400000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738782000000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738782600000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738783200000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738783800000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738784400000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738785000000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738785600000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738786200000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738786800000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738787400000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738788000000,
            "y": 2.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738788600000,
            "y": 2.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738789200000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738789800000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738790400000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738791000000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738791600000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738792200000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738792800000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738793400000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738794000000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738794600000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738795200000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738795800000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738796400000,
            "y": 2.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738797000000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738797600000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738798200000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738798800000,
            "y": 2.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738799400000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738800000000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738800600000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738801200000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738801800000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738802400000,
            "y": 2.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738803000000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738803600000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738804200000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738804800000,
            "y": 2.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738805400000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738806000000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738806600000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738807200000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738807800000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738808400000,
            "y": 2.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738809000000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738809600000,
            "y": 2.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738810200000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738810800000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738811400000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738812000000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738812600000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738813200000,
            "y": 2.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738813800000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738814400000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738815000000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738815600000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738816200000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738816800000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738817400000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738818000000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738818600000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738819200000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738819800000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738820400000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738821000000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738821600000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738822200000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738822800000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738823400000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738824000000,
            "y": 2.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738824600000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738825200000,
            "y": 2.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738825800000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738826400000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738827000000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738827600000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738828200000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738828800000,
            "y": 2.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738829400000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738830000000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738830600000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738831200000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738831800000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738832400000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738833000000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738833600000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738834200000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738834800000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738835400000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738836000000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738836600000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738837200000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738837800000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738838400000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738839000000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738839600000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738840200000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738840800000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738841400000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738842000000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738842600000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738843200000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738843800000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738844400000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738845000000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738845600000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738846200000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738846800000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738847400000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738848000000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738848600000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738849200000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738849800000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738850400000,
            "y": 2.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738851000000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738851600000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738852200000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738852800000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738853400000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738854000000,
            "y": 2.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738854600000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738855200000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738855800000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738856400000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738857000000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738857600000,
            "y": 2.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738858200000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738858800000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738859400000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738860000000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738860600000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738861200000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738861800000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738862400000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738863000000,
            "y": 2.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738863600000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738864200000,
            "y": 2.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738864800000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738865400000,
            "y": 2.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738866000000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738866600000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738867200000,
            "y": 2.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738867800000,
            "y": 2.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738868400000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738869000000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738869600000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738870200000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738870800000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738871400000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738872000000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738872600000,
            "y": 2.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738873200000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738873800000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738874400000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738875000000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738875600000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738876200000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738876800000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738877400000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738878000000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738878600000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738879200000,
            "y": 2.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738879800000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738880400000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738881000000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738881600000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738882200000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738882800000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738883400000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738884000000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738884600000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738885200000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738885800000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738886400000,
            "y": 2.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738887000000,
            "y": 2.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738887600000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738888200000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738888800000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738889400000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738890000000,
            "y": 2.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738890600000,
            "y": 2.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738891200000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738891800000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738892400000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738893000000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738893600000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738894200000,
            "y": 2.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738894800000,
            "y": 2.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738895400000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738896000000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738896600000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738897200000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738897800000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738898400000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738899000000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738899600000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738900200000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738900800000,
            "y": 2.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738901400000,
            "y": 2.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738902000000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738902600000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738903200000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738903800000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738904400000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738905000000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738905600000,
            "y": 2.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738906200000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738906800000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738907400000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738908000000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738908600000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738909200000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738909800000,
            "y": 2.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738910400000,
            "y": 2.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738911000000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738911600000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738912200000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738912800000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738913400000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738914000000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738914600000,
            "y": 2.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738915200000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738915800000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738916400000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738917000000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738917600000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738918200000,
            "y": 2.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738918800000,
            "y": 2.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738919400000,
            "y": 2.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738920000000,
            "y": 2.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738920600000,
            "y": 2.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738921200000,
            "y": 2.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738921800000,
            "y": 2.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738922400000,
            "y": 2.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738923000000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738923600000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738924200000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738924800000,
            "y": 2.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738925400000,
            "y": 2.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738926000000,
            "y": 2.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738926600000,
            "y": 2.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738927200000,
            "y": 2.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738927800000,
            "y": 2.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          }
        ],
        "40": [
          {
            "x": 1738537200000,
            "y": 3.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738537800000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738538400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738539000000,
            "y": 3.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738539600000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738540200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738540800000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738541400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738542000000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738542600000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738543200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738543800000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738544400000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738545000000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738545600000,
            "y": 3.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738546200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738546800000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738547400000,
            "y": 3.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738548000000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738548600000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738549200000,
            "y": 3.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738549800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738550400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738551000000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738551600000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738552200000,
            "y": 3.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738552800000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738553400000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738554000000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738554600000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738555200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738555800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738556400000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738557000000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738557600000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738558200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738558800000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738559400000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738560000000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738560600000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738561200000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738561800000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738562400000,
            "y": 3.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738563000000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738563600000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738564200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738564800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738565400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738566000000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738566600000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738567200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738567800000,
            "y": 3.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738568400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738569000000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738569600000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738570200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738570800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738571400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738572000000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738572600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738573200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738573800000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738574400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738575000000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738575600000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738576200000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738576800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738577400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738578000000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738578600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738579200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738579800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738580400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738581000000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738581600000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738582200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738582800000,
            "y": 3.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738583400000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738584000000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738584600000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738585200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738585800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738586400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738587000000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738587600000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738588200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738588800000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738589400000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738590000000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738590600000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738591200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738591800000,
            "y": 3.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738592400000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738593000000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738593600000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738594200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738594800000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738595400000,
            "y": 3.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738596000000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738596600000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738597200000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738597800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738598400000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738599000000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738599600000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738600200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738600800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738601400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738602000000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738602600000,
            "y": 3.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738603200000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738603800000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738604400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738605000000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738605600000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738606200000,
            "y": 3.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738606800000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738607400000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738608000000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738608600000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738609200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738609800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738610400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738611000000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738611600000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738612200000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738612800000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738613400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738614000000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738614600000,
            "y": 3.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738615200000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738615800000,
            "y": 3.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738616400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738617000000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738617600000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738618200000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738618800000,
            "y": 3.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738619400000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738620000000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738620600000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738621200000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738621800000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738622400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738623000000,
            "y": 3.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738623600000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738624200000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738624800000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738625400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738626000000,
            "y": 3.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738626600000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738627200000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738627800000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738628400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738629000000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738629600000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738630200000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738630800000,
            "y": 3.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738631400000,
            "y": 3.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738632000000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738632600000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738633200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738633800000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738634400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738635000000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738635600000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738636200000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738636800000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738637400000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738638000000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738638600000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738639200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738639800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738640400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738641000000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738641600000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738642200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738642800000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738643400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738644000000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738644600000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738645200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738645800000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738646400000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738647000000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738647600000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738648200000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738648800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738649400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738650000000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738650600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738651200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738651800000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738652400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738653000000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738653600000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738654200000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738654800000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738655400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738656000000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738656600000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738657200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738657800000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738658400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738659000000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738659600000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738660200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738660800000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738661400000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738662000000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738662600000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738663200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738663800000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738664400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738665000000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738665600000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738666200000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738666800000,
            "y": 3.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738667400000,
            "y": 3.05,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738668000000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738668600000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738669200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738669800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738670400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738671000000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738671600000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738672200000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738672800000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738673400000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738674000000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738674600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738675200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738675800000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738676400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738677000000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738677600000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738678200000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738678800000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738679400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738680000000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738680600000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738681200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738681800000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738682400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738683000000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738683600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738684200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738684800000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738685400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738686000000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738686600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738687200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738687800000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738688400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738689000000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738689600000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738690200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738690800000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738691400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738692000000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738692600000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738693200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738693800000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738694400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738695000000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738695600000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738696200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738696800000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738697400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738698000000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738698600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738699200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738699800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738700400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738701000000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738701600000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738702200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738702800000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738703400000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738704000000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738704600000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738705200000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738705800000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738706400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738707000000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738707600000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738708200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738708800000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738709400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738710000000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738710600000,
            "y": 3.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738711200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738711800000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738712400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738713000000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738713600000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738714200000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738714800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738715400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738716000000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738716600000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738717200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738717800000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738718400000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738719000000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738719600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738720200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738720800000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738721400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738722000000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738722600000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738723200000,
            "y": 3.05,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738723800000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738724400000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738725000000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738725600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738726200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738726800000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738727400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738728000000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738728600000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738729200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738729800000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738730400000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738731000000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738731600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738732200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738732800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738733400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738734000000,
            "y": 3.05,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738734600000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738735200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738735800000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738736400000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738737000000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738737600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738738200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738738800000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738739400000,
            "y": 3.05,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738740000000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738740600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738741200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738741800000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738742400000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738743000000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738743600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738744200000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738744800000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738745400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738746000000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738746600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738747200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738747800000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738748400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738749000000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738749600000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738750200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738750800000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738751400000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738752000000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738752600000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738753200000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738753800000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738754400000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738755000000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738755600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738756200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738756800000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738757400000,
            "y": 3.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738758000000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738758600000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738759200000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738759800000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738760400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738761000000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738761600000,
            "y": 2.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738762200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738762800000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738763400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738764000000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738764600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738765200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738765800000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738766400000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738767000000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738767600000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738768200000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738768800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738769400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738770000000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738770600000,
            "y": 3.05,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738771200000,
            "y": 3.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738771800000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738772400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738773000000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738773600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738774200000,
            "y": 3.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738774800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738775400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738776000000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738776600000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738777200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738777800000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738778400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738779000000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738779600000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738780200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738780800000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738781400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738782000000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738782600000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738783200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738783800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738784400000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738785000000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738785600000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738786200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738786800000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738787400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738788000000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738788600000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738789200000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738789800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738790400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738791000000,
            "y": 3.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738791600000,
            "y": 3.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738792200000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738792800000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738793400000,
            "y": 3.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738794000000,
            "y": 3.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738794600000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738795200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738795800000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738796400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738797000000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738797600000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738798200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738798800000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738799400000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738800000000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738800600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738801200000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738801800000,
            "y": 3.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738802400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738803000000,
            "y": 3.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738803600000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738804200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738804800000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738805400000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738806000000,
            "y": 3.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738806600000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738807200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738807800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738808400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738809000000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738809600000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738810200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738810800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738811400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738812000000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738812600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738813200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738813800000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738814400000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738815000000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738815600000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738816200000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738816800000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738817400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738818000000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738818600000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738819200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738819800000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738820400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738821000000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738821600000,
            "y": 3.05,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738822200000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738822800000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738823400000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738824000000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738824600000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738825200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738825800000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738826400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738827000000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738827600000,
            "y": 3.05,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738828200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738828800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738829400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738830000000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738830600000,
            "y": 3.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738831200000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738831800000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738832400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738833000000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738833600000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738834200000,
            "y": 3.05,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738834800000,
            "y": 3.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738835400000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738836000000,
            "y": 3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738836600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738837200000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738837800000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738838400000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738839000000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738839600000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738840200000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738840800000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738841400000,
            "y": 3.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738842000000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738842600000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738843200000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738843800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738844400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738845000000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738845600000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738846200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738846800000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738847400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738848000000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738848600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738849200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738849800000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738850400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738851000000,
            "y": 3.05,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738851600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738852200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738852800000,
            "y": 3.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738853400000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738854000000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738854600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738855200000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738855800000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738856400000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738857000000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738857600000,
            "y": 3.05,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738858200000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738858800000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738859400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738860000000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738860600000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738861200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738861800000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738862400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738863000000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738863600000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738864200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738864800000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738865400000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738866000000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738866600000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738867200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738867800000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738868400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738869000000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738869600000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738870200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738870800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738871400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738872000000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738872600000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738873200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738873800000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738874400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738875000000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738875600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738876200000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738876800000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738877400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738878000000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738878600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738879200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738879800000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738880400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738881000000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738881600000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738882200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738882800000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738883400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738884000000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738884600000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738885200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738885800000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738886400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738887000000,
            "y": 2.98,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738887600000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738888200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738888800000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738889400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738890000000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738890600000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738891200000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738891800000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738892400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738893000000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738893600000,
            "y": 3.05,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738894200000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738894800000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738895400000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738896000000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738896600000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738897200000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738897800000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738898400000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738899000000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738899600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738900200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738900800000,
            "y": 3.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738901400000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738902000000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738902600000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738903200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738903800000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738904400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738905000000,
            "y": 3.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738905600000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738906200000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738906800000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738907400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738908000000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738908600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738909200000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738909800000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738910400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738911000000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738911600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738912200000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738912800000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738913400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738914000000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738914600000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738915200000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738915800000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738916400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738917000000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738917600000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738918200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738918800000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738919400000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738920000000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738920600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738921200000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738921800000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738922400000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738923000000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738923600000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738924200000,
            "y": 3.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738924800000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738925400000,
            "y": 3.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738926000000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738926600000,
            "y": 3.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738927200000,
            "y": 3.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738927800000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          }
        ],
        "60": [
          {
            "x": 1738537200000,
            "y": 3.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738537800000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738538400000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738539000000,
            "y": 3.42,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738539600000,
            "y": 3.42,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738540200000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738540800000,
            "y": 3.43,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738541400000,
            "y": 3.42,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738542000000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738542600000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738543200000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738543800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738544400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738545000000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738545600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738546200000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738546800000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738547400000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738548000000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738548600000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738549200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738549800000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738550400000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738551000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738551600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738552200000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738552800000,
            "y": 3.42,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738553400000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738554000000,
            "y": 3.42,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738554600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738555200000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738555800000,
            "y": 3.42,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738556400000,
            "y": 3.42,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738557000000,
            "y": 3.43,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738557600000,
            "y": 3.42,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738558200000,
            "y": 3.44,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738558800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738559400000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738560000000,
            "y": 3.42,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738560600000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738561200000,
            "y": 3.44,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738561800000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738562400000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738563000000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738563600000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738564200000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738564800000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738565400000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738566000000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738566600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738567200000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738567800000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738568400000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738569000000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738569600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738570200000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738570800000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738571400000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738572000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738572600000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738573200000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738573800000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738574400000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738575000000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738575600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738576200000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738576800000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738577400000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738578000000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738578600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738579200000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738579800000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738580400000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738581000000,
            "y": 3.43,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738581600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738582200000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738582800000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738583400000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738584000000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738584600000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738585200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738585800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738586400000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738587000000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738587600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738588200000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738588800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738589400000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738590000000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738590600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738591200000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738591800000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738592400000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738593000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738593600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738594200000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738594800000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738595400000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738596000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738596600000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738597200000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738597800000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738598400000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738599000000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738599600000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738600200000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738600800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738601400000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738602000000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738602600000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738603200000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738603800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738604400000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738605000000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738605600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738606200000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738606800000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738607400000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738608000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738608600000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738609200000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738609800000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738610400000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738611000000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738611600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738612200000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738612800000,
            "y": 3.42,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738613400000,
            "y": 3.42,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738614000000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738614600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738615200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738615800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738616400000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738617000000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738617600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738618200000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738618800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738619400000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738620000000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738620600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738621200000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738621800000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738622400000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738623000000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738623600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738624200000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738624800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738625400000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738626000000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738626600000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738627200000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738627800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738628400000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738629000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738629600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738630200000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738630800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738631400000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738632000000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738632600000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738633200000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738633800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738634400000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738635000000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738635600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738636200000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738636800000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738637400000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738638000000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738638600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738639200000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738639800000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738640400000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738641000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738641600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738642200000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738642800000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738643400000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738644000000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738644600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738645200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738645800000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738646400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738647000000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738647600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738648200000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738648800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738649400000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738650000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738650600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738651200000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738651800000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738652400000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738653000000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738653600000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738654200000,
            "y": 3.43,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738654800000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738655400000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738656000000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738656600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738657200000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738657800000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738658400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738659000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738659600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738660200000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738660800000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738661400000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738662000000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738662600000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738663200000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738663800000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738664400000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738665000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738665600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738666200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738666800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738667400000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738668000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738668600000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738669200000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738669800000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738670400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738671000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738671600000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738672200000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738672800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738673400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738674000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738674600000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738675200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738675800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738676400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738677000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738677600000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738678200000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738678800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738679400000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738680000000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738680600000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738681200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738681800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738682400000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738683000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738683600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738684200000,
            "y": 3.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738684800000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738685400000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738686000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738686600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738687200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738687800000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738688400000,
            "y": 3.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738689000000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738689600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738690200000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738690800000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738691400000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738692000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738692600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738693200000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738693800000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738694400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738695000000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738695600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738696200000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738696800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738697400000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738698000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738698600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738699200000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738699800000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738700400000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738701000000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738701600000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738702200000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738702800000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738703400000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738704000000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738704600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738705200000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738705800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738706400000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738707000000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738707600000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738708200000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738708800000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738709400000,
            "y": 3.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738710000000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738710600000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738711200000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738711800000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738712400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738713000000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738713600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738714200000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738714800000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738715400000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738716000000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738716600000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738717200000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738717800000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738718400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738719000000,
            "y": 3.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738719600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738720200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738720800000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738721400000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738722000000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738722600000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738723200000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738723800000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738724400000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738725000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738725600000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738726200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738726800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738727400000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738728000000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738728600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738729200000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738729800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738730400000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738731000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738731600000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738732200000,
            "y": 3.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738732800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738733400000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738734000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738734600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738735200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738735800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738736400000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738737000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738737600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738738200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738738800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738739400000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738740000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738740600000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738741200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738741800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738742400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738743000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738743600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738744200000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738744800000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738745400000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738746000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738746600000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738747200000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738747800000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738748400000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738749000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738749600000,
            "y": 3.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738750200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738750800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738751400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738752000000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738752600000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738753200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738753800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738754400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738755000000,
            "y": 3.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738755600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738756200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738756800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738757400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738758000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738758600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738759200000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738759800000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738760400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738761000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738761600000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738762200000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738762800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738763400000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738764000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738764600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738765200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738765800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738766400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738767000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738767600000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738768200000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738768800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738769400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738770000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738770600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738771200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738771800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738772400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738773000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738773600000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738774200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738774800000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738775400000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738776000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738776600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738777200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738777800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738778400000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738779000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738779600000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738780200000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738780800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738781400000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738782000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738782600000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738783200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738783800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738784400000,
            "y": 3.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738785000000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738785600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738786200000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738786800000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738787400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738788000000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738788600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738789200000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738789800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738790400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738791000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738791600000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738792200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738792800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738793400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738794000000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738794600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738795200000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738795800000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738796400000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738797000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738797600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738798200000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738798800000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738799400000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738800000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738800600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738801200000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738801800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738802400000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738803000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738803600000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738804200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738804800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738805400000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738806000000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738806600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738807200000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738807800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738808400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738809000000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738809600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738810200000,
            "y": 3.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738810800000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738811400000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738812000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738812600000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738813200000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738813800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738814400000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738815000000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738815600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738816200000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738816800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738817400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738818000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738818600000,
            "y": 3.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738819200000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738819800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738820400000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738821000000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738821600000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738822200000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738822800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738823400000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738824000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738824600000,
            "y": 3.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738825200000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738825800000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738826400000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738827000000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738827600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738828200000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738828800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738829400000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738830000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738830600000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738831200000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738831800000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738832400000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738833000000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738833600000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738834200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738834800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738835400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738836000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738836600000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738837200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738837800000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738838400000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738839000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738839600000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738840200000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738840800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738841400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738842000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738842600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738843200000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738843800000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738844400000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738845000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738845600000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738846200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738846800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738847400000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738848000000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738848600000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738849200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738849800000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738850400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738851000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738851600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738852200000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738852800000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738853400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738854000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738854600000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738855200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738855800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738856400000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738857000000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738857600000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738858200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738858800000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738859400000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738860000000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738860600000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738861200000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738861800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738862400000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738863000000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738863600000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738864200000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738864800000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738865400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738866000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738866600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738867200000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738867800000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738868400000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738869000000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738869600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738870200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738870800000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738871400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738872000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738872600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738873200000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738873800000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738874400000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738875000000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738875600000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738876200000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738876800000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738877400000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738878000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738878600000,
            "y": 3.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738879200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738879800000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738880400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738881000000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738881600000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738882200000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738882800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738883400000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738884000000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738884600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738885200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738885800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738886400000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738887000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738887600000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738888200000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738888800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738889400000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738890000000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738890600000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738891200000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738891800000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738892400000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738893000000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738893600000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738894200000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738894800000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738895400000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738896000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738896600000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738897200000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738897800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738898400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738899000000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738899600000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738900200000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738900800000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738901400000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738902000000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738902600000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738903200000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738903800000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738904400000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738905000000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738905600000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738906200000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738906800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738907400000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738908000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738908600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738909200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738909800000,
            "y": 3.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738910400000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738911000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738911600000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738912200000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738912800000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738913400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738914000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738914600000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738915200000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738915800000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738916400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738917000000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738917600000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738918200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738918800000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738919400000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738920000000,
            "y": 3.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738920600000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738921200000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738921800000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738922400000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738923000000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738923600000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738924200000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738924800000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738925400000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738926000000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738926600000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738927200000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1738927800000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          }
        ]
      }
    }
  }